import React, { useEffect } from "react";
import LoginForm from "../components/loginform";
import "../components/login.css";

const Login = () => {
  useEffect(() => {
    document.title = "Foundy";
  }, []);
  return (
    <div className="App">
      <LoginForm />
    </div>
  );
};

export default Login;
