import MaplibreGeocoder from "@maplibre/maplibre-gl-geocoder";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MapIcon from "@mui/icons-material/Map";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Link,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Chip,
  Skeleton,
  Card,
  Badge,
  CardContent,
  Slider,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { deepOrange } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as turf from "@turf/turf";
import { MapboxValhallaControl } from "@watergis/mapbox-gl-valhalla";
import "@watergis/mapbox-gl-valhalla/css/styles.css";
import axios from "axios";
import dayjs from "dayjs";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useEffect, useRef, useState } from "react";
import Map, {
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import "../components/marker.css";
import "../components/geocoder.css";
import Navbar from "../components/navbar";
import {
  addPartnersAndAvoidDuplicates,
  addFailedPartners,
  addFoundInstallers,
  addPartners,
  disablePartners,
  incrementNafRadius,
  setFoundAtLeastOneInstaller,
  setFoundInstallers,
  setNafRadius,
  setPartnerInstalling,
  setPartners,
  setPartnerSelling,
  setSearchStep,
  setSelectedPartner,
  setSelectedPartnerComments,
  removeFoundInstaller,
  replaceFoundInstallerIsochrone,
  updateTimeCostForInstaller,
  removeNafAndExternalPartners,
  setSearchType,
  setShowDisabledPartners,
  setShowConventionnedPartners,
  setShowNonConventionnedPartners,
  setShowAquaPartners,
} from "../partner-slice";
import {
  setOpacity,
  setPrelevements,
  setCommune,
  appendPrelevement,
  appendPrelevements,
  setDoubleClickLngLat,
} from "../heatmap-slice";
import { useParams } from "react-router-dom";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
//import { circleLayer, textLayer } from '../components/heatmap';
import { CSVLink, CSVDownload } from "react-csv";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Pagination } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import cheerio from "cheerio";
import { sosPartners } from "../sos-partners";
import GoogleLogo from "../google.webp";
import PagesJaunesLogo from "../pagesjaunes.png";
import store from "../store";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import getComments from "../components/comments";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const MainApp = () => {
  var geocoder_api = {
    forwardGeocode: async (config) => {
      const features = [];
      console.log(store.getState().partners.searchType);
      if (store.getState().partners.searchType == "partner") {
        var foundPartnersWithDuplicate = store
          .getState()
          .partners.partners.filter(
            (elem) =>
              elem.name.toLowerCase().indexOf(config.query.toLowerCase()) > -1
          );
        var foundPartners = filterPartnersWithoutDuplicate(
          foundPartnersWithDuplicate
        );

        /*} else if(partnerSearchProvider == "pentair") {
                    //94170+Le+Perreux-sur-Marne
                    // à déplacer vers recherche normale pour avoir les lng lat et display les btn
                    var foundPartnersAsInstallers = await axios.get('/api/v1/pentair?field_dl_coordinates_proximity_center[coordinates][lat]=48.841386&field_dl_coordinates_proximity_center[coordinates][lng]=2.504038')
                    .then(res => {
                        const $ = cheerio.load(res.data);

                        const foundArray = $('.result-list > li')
                        .map((_, found) => {
                            const $found = $(found);
                            const name = $found.find('.views-field-title > .field-content').text()
                            const address = $found.find('.views-field-field-dl-address > .field-content').text()
                            const phone = $found.find('.views-field-field-dl-phone > .field-content').text()
                            return {pointInstallateur: turf.point(
                                ['2.5035059', '48.840305'], // store long lat for calculations via turf.js
                                {'partner_longitude':'2.5035059','partner_latitude':'48.840305','phone':phone,'address':address,siret:'0','name':name} // store actual partner in point.properties to keep it's personal infos after treatment
                                ), isochroneInstallateur: null, costInstallateur: {time: 0}}
                        })
                        .toArray();

                        return foundArray;
                    })
                    alert(foundPartnersAsInstallers);
                    dispatch(setFoundInstallers(foundPartnersAsInstallers));
                } else if(partnerSearchProvider == "sos") {
                    //console.log(sosPartners.map(elem => {
                    //    return {"email":elem.contactEmail,"city":elem.name,"phone":elem.tel,"locate":elem.locate}
                    //}));
                }*/

        return {
          features: foundPartnersWithDuplicate.map((elem) => {
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [elem.partner_longitude, elem.partner_latitude],
              },
              place_name: elem.name.trim(),
              text: elem.name.trim(),
              place_type: ["place"],
              center: [elem.partner_longitude, elem.partner_latitude],
            };
          }),
        };
      }
      try {
        setSearching(true);
        let request =
          "https://api-adresse.data.gouv.fr/search/?q=" + config.query;
        /*'https://nominatim.openstreetmap.org/search?q=' +
                    config.query +
                    '&format=geojson&polygon_geojson=1&addressdetails=1';*/
        const response = await fetch(request);
        const geojson = await response.json();
        for (let feature of geojson.features) {
          /*let center = [
                        feature.bbox[0] +
                            (feature.bbox[2] - feature.bbox[0]) / 2,
                        feature.bbox[1] +
                            (feature.bbox[3] - feature.bbox[1]) / 2
                    ];*/
          let center = [
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ];
          let point = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: center,
            },
            //place_name: feature.properties.display_name,
            place_name: feature.properties.label,
            properties: feature.properties,
            //text: feature.properties.display_name,
            text: feature.properties.label,
            place_type: ["place"],
            center: center,
          };
          features.push(point);
        }
      } catch (e) {
        setSearching(false);
        console.error(`Failed to forwardGeocode with error: ${e}`);
      }

      return {
        features: features,
      };
    },
  };

  const mapRef = React.useRef();
  const [allData, setAllData] = useState(null);
  const [searching, setSearching] = useState(false);
  const [clientGeojson, setClientGeojson] = useState(null);
  const [clientPosition, setClientPosition] = useState(null);
  const searchStep = useSelector((state) => state.partners.searchStep);
  const partnersData = useSelector((state) => state.partners.partners);
  const disabledPartners = useSelector(
    (state) => state.partners.disabledPartners
  );
  const showDisabledPartners = useSelector(
    (state) => state.partners.showDisabledPartners
  );
  const showConventionnedPartners = useSelector(
    (state) => state.partners.showConventionnedPartners
  );
  const showNonConventionnedPartners = useSelector(
    (state) => state.partners.showNonConventionnedPartners
  );
  const showAquaPartners = useSelector(
    (state) => state.partners.showAquaPartners
  );
  const failedPartners = useSelector((state) => state.partners.failedPartners);
  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner
  );
  const selectedPartnerComments = useSelector(
    (state) => state.partners.selectedPartnerComments
  );
  const foundInstallers = useSelector(
    (state) => state.partners.foundInstallers
  );
  const foundAtLeastOneInstaller = useSelector(
    (state) => state.partners.foundAtLeastOneInstaller
  );
  const nafRadius = useSelector((state) => state.partners.nafRadius);
  const [calculating, setCalculating] = useState(false);

  const [recontactValue, setRecontactValue] = useState(null);
  const [installingExpiralDateModal, setInstallingExpiralDateModal] =
    useState(null);
  const [recontactDate, setRecontactDate] = useState(null);

  const [heatmapRange, setHeatmapRange] = useState([30, 100]);
  const heatmapOpacity = useSelector((state) => state.heatmap.opacity);

  const commune = useSelector((state) => state.heatmap.commune);
  const prelevements = useSelector((state) => state.heatmap.prelevements);

  const doubleClickLng = useSelector((state) => state.heatmap.doubleClickLng);
  const doubleClickLat = useSelector((state) => state.heatmap.doubleClickLat);

  const drawControl = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      trash: true,
    },
    defaultMode: "draw_polygon",
  });
  const [drawOpen, setDrawOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const isochroneUrl = window.location.origin + "/api/v1/isochrone";
  const circleLayer = {
    id: "circleLayer",
    type: "circle",
    source: "1fwr3mj6i",
    paint: {
      "circle-stroke-opacity": heatmapOpacity / 100,
      "circle-color": "#000000",
      "circle-stroke-width": 20,
      "circle-stroke-color": [
        "interpolate",
        ["linear"],
        ["*", 0.03, ["get", "moyenneTh"]],
        0,
        "rgba(0, 0, 255, 0)",
        0.1,
        "royalblue",
        0.3,
        "cyan",
        0.5,
        "lime",
        0.7,
        "yellow",
        1,
        "red",
      ],
      "circle-radius": 0,
    },
  };

  const textLayer = {
    id: "textLayer",
    type: "symbol",
    source: "1fwr3mj6i",
    layout: {
      "text-field": ["get", "moyenneTh"],
      "text-size": 15,
      "text-padding": 0,
      "text-font": ["Open Sans Regular"],
    },
    paint: {
      "text-color": "rgba(10, 9, 9, 1)",
      "text-opacity": heatmapOpacity / 100,
    },
  };

  const handleHeatmapRangeChange = (event, newValue) => {
    setHeatmapRange(newValue);
  };

  const handleHeatmapOpacityChange = (event, newValue) => {
    dispatch(setOpacity(newValue));
  };

  function getRandomColor(name) {
    // get first alphabet in upper case
    const firstAlphabet = name.charAt(0).toLowerCase();

    // get the ASCII code of the character
    const asciiCode = firstAlphabet.charCodeAt(0);

    // number that contains 3 times ASCII value of character -- unique for every alphabet
    const colorNum =
      asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = (num >> 16) & 255;
    var g = (num >> 8) & 255;
    var b = num & 255;

    return "rgb(" + r + ", " + g + ", " + b + ", 1)";
  }

  const dispatch = useDispatch();
  //const {roles} = useLocation();
  const roles = localStorage.getItem("roles");
  const email = localStorage.getItem("email");
  const { defaultLongitude, defaultLatitude } = useParams();
  const { option } = useParams();

  const handleDoubleClick = (e) => {
    if (selectedLayer == "prospects") {
      e.preventDefault();
      console.log("double-click : search naf");
      let lngLat = e.lngLat.toArray();
      setPage(1);
      setClientPosition(lngLat);
      setClientGeojson(turf.featureCollection([turf.circle(lngLat, 300)])); // 300 kilometres
    }

    if (selectedLayer == "heatmap") {
      e.preventDefault();
      dispatch(setPrelevements([]));
      dispatch(setCommune(null));
      let longitude = e.lngLat.toArray()[0];
      let latitude = e.lngLat.toArray()[1];
      dispatch(setDoubleClickLngLat([longitude, latitude]));
      fetch(
        "https://geo.api.gouv.fr/communes?lat=" +
          latitude +
          "&lon=" +
          longitude +
          "&fields=nom,code,codesPostaux,siren,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre"
      )
        .then((resp) => resp.json())
        .then((json) => {
          var codeInsee = json[0].code;
          var nom = json[0].nom + " " + json[0].codeDepartement;
          console.log("Code INSEE trouvé " + codeInsee);
          fetch(
            "https://hubeau.eaufrance.fr/api/v1/qualite_eau_potable/communes_udi?annee=2022&code_commune=" +
              codeInsee
          )
            .then((resp) => resp.json())
            .then((json) => {
              if (json.data.length > 0) {
                dispatch(setCommune(json.data));
              }
              json.data.forEach((element) => {
                fetch(
                  "https://hubeau.eaufrance.fr/api/v1/qualite_eau_potable/resultats_dis?code_reseau=" +
                    element.code_reseau +
                    "&code_parametre=1345&sort=desc"
                )
                  .then((resp) => resp.json())
                  .then((json) => {
                    if (json.count != 0) {
                      var prelevementsResult = [];
                      var liste_install_amont = [];
                      var liste_commune = [];
                      json.data.map((elem) => {
                        var elemHasBeenFound = false;
                        prelevementsResult = prelevementsResult.map((x) => {
                          let hasCIAandMatching =
                            elem["code_installation_amont"] &&
                            x["code_installation_amont"] &&
                            elem.code_installation_amont ==
                              x.code_installation_amont;
                          let hasCommuneAndMatching =
                            elem["code_installation_amont"] == null &&
                            x["code_installation_amont"] == null &&
                            elem.code_commune == x.code_commune;
                          if (hasCIAandMatching || hasCommuneAndMatching) {
                            elemHasBeenFound = true;
                            let hasNewerElem =
                              new Date(x.date_prelevement) <
                              new Date(elem.date_prelevement);
                            if (hasNewerElem) {
                              return elem;
                            } else {
                              return x;
                            }
                          } else {
                            return x;
                          }
                        });
                        if (elemHasBeenFound == false) {
                          prelevementsResult.push(elem);
                          if ("code_installation_amont" in elem) {
                            liste_install_amont.push(
                              elem.code_installation_amont
                            );
                          } else {
                            liste_commune.push(elem.code_commune);
                          }
                        }
                      });
                      console.log("prélèvements trouvés");
                      dispatch(appendPrelevements(prelevementsResult));
                    } else {
                      console.log("prélèvements non trouvés");
                    }
                  });
              });
            });
        })
        .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
    }
  };

  const onMapLoad = React.useCallback(() => {
    console.log("roles : " + roles);

    if (
      roles == "[AQUA2000]" ||
      email == "contact@skavi.fr" ||
      email == "t.gosselin@aqua2000.fr" ||
      email == "kaviane@hotmail.fr"
    )
      setSelectedLayer("installers");
    else setSelectedLayer("prospects");

    const markerEl = document.createElement("div");
    const markerWidth = "48px";
    const markerHeight = "48px";
    markerEl.className = "marker marker-client";
    markerEl.style.backgroundImage = `url(/epingle.png)`;
    markerEl.style.filter =
      "hue-rotate(50deg) saturate(10) drop-shadow(rgb(121, 121, 121) 4px 2px 3px)";
    markerEl.style.width = `${markerWidth}px;`;
    markerEl.style.height = `${markerHeight}px;`;
    //markerEl.textContent = "";

    /*var markerText = document.createElement("p");
        markerText.textContent = "CLIENT";
        markerEl.appendChild(markerText);*/

    Promise.all([fetchPartners(dispatch), fetchFailedPartners(dispatch)]).then(
      () => {
        if (defaultLongitude && defaultLatitude) {
          console.log("default long/lat provided");
          var marker = new maplibregl.Marker({
            element: markerEl,
            draggable: false,
          })
            .setLngLat([defaultLongitude, defaultLatitude])
            .addTo(mapRef.current.getMap());

          setClientPosition([defaultLongitude, defaultLatitude]);
          setClientGeojson(
            turf.featureCollection([
              turf.circle([defaultLongitude, defaultLatitude], 300),
            ])
          ); // 300 kilometres
        }
      }
    );
    var geocoder = new MaplibreGeocoder(geocoder_api, {
      maplibregl: maplibregl,
      marker: { element: markerEl, draggable: false },
      countries: "fr", //,
      /*filter: function (item) {
                return item.properties.display_name.includes("France");
            },*/
    });
    mapRef.current.addControl(geocoder);
    geocoder.on("result", function (e) {
      if (store.getState().partners.searchType == "partner") {
        var foundPartnersWithDuplicate = store
          .getState()
          .partners.partners.filter(
            (elem) =>
              elem.name.toLowerCase().indexOf(e.result.text.toLowerCase()) > -1
          );
        console.log(foundPartnersWithDuplicate);
        var foundPartners = filterPartnersWithoutDuplicate(
          foundPartnersWithDuplicate
        );
        console.log(foundPartners);
        var foundPartnersAsInstallers = foundPartners.map((partner, i) => {
          return {
            pointInstallateur: turf.point(
              [partner.partner_longitude, partner.partner_latitude], // store long lat for calculations via turf.js
              structuredClone(partner) // store actual partner in point.properties to keep it's personal infos after treatment
            ),
            isochroneInstallateur: null,
            costInstallateur: { time: 0 },
          };
        });
        dispatch(setFoundInstallers(foundPartnersAsInstallers));
      } else {
        setSearching(false);
        setPage(1);

        var lon = e.result.geometry.coordinates[0];
        var lat = e.result.geometry.coordinates[1];

        setClientPosition([lon, lat]);
        setClientGeojson(
          turf.featureCollection([turf.circle([lon, lat], 300)])
        ); // 300 kilometres
      }
    });

    mapRef.current.addControl(
      new MapboxValhallaControl("http://51.158.61.171:8002", {
        Contours: [
          {
            time: 3,
            distance: 1,
            color: "ff0000",
          },
          {
            time: 5,
            distance: 2,
            color: "ffff00",
          },
          {
            time: 10,
            distance: 3,
            color: "0000ff",
          },
        ],
      }),
      "bottom-right"
    );
  }, []);

  useEffect(() => {
    document.title = "Foundy";
    /* global fetch */
    fetch("https://aqua2000.fr/wp-tools/durete/durete.json", {
      cache: "no-store",
    })
      .then((resp) => resp.json())
      .then((json) => setAllData(json))
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
  }, []);

  React.useEffect(async () => {
    if (foundInstallers !== null && foundInstallers.length > 0) {
      setCalculating(false);
    }
  }, [foundInstallers]);

  function filterPartnersWithoutDuplicate(partnersArray) {
    // TODO : ODOO time should be synced with COLORS ones (duplicate cases), or at least ODOO be bigger than Colors everytime
    // Otherwise, we have to do this complex and performance-hogging checkup ...
    return partnersArray.filter((item) => {
      var potentiEquivInOtherCRM = partnersArray.find(
        (p) => p.siret == item.siret && p.origin != item.origin
      );
      if (!item.duplicate) {
        // If this isn't a detected duplicate (ODOO point 100%)
        // If there is an equivalent in Colors, and our ODOO time is bigger than that COLORS time
        // Return our ODOO point
        if (
          potentiEquivInOtherCRM != null &&
          item.time >= potentiEquivInOtherCRM.time
        ) {
          return true;
          // If there is no equivalent in Colors, ofc return our ODOO point
        } else if (potentiEquivInOtherCRM == null) {
          return true;
          // That means we have an equivalent in Colors, and its time is bigger than ODOO time so we can't show ODOO
        } else {
          return false;
        }
      } else {
        // This is a detected duplicate (COLORS point which already has an ODOO alternative)
        // With the original ODOO point, check that the COLORS point indeed has bigger time than ODOO point
        if (
          potentiEquivInOtherCRM != null &&
          item.time > potentiEquivInOtherCRM.time
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
  }

  React.useEffect(async () => {
    if (clientGeojson !== null && selectedLayer != "heatmap") {
      if (partnersData) {
        Promise.all([
          setCalculating(true),
          await dispatch(removeNafAndExternalPartners()),
          await dispatch(setFoundInstallers(null)),
          dispatch(setNafRadius(null)),
        ]).then(() => {
          if (selectedLayer == "prospects") {
            // temporary
            dispatch(setFoundAtLeastOneInstaller(false));
            dispatch(setSearchStep(7));
          } else {
            // TODO : ODOO time should be synced with COLORS ones (duplicate cases), or at least ODOO be bigger than Colors everytime
            // Otherwise, we have to do this complex and performance-hogging checkup ...
            let allPartnersWithoutDuplicate =
              filterPartnersWithoutDuplicate(partnersData);
            calculateClient(allPartnersWithoutDuplicate);
            //calculateClient(partnersData.filter(partner => partner.origin == 'odoo' && partner.conventionne && partner.conventionne == true));
          }
        });
      }
    }
  }, [clientGeojson]);

  React.useEffect(() => {
    if (foundAtLeastOneInstaller === false) {
      console.log("recalculating, " + searchStep);
      setPage(1);
      switch (searchStep) {
        case 1:
          let allPartnersWithoutDuplicate =
            filterPartnersWithoutDuplicate(partnersData);
          calculateClient(allPartnersWithoutDuplicate);
          break;
        case 2:
          calculateClient(
            partnersData.filter(
              (partner) =>
                partner.origin == "odoo" &&
                partner.conventionne &&
                partner.conventionne == true
            )
          );
          break;
        case 3:
          calculateClient(
            partnersData.filter((partner) => partner.origin == "colors")
          );
          break;
        case 4:
          calculateClient(
            partnersData.filter((partner) => partner.origin == "odoo")
          );
          break;
        case 5:
          setCalculating(true);
          fetchSosBricolagePartners();
          break;
        case 6:
          setCalculating(true);
          fetchPentairPartners();
          break;
        case 7:
          setCalculating(false);
          if (nafRadius == null) {
            dispatch(setNafRadius(1));
            fetchNafPartners(1);
          } else {
            console.log("anomalie");
            fetchNafPartners(nafRadius);
          }

          break;
        default:
        // code block
      }
    }
  }, [searchStep]);

  React.useEffect(() => {
    if (mapRef.current) {
      var mapLayer = mapRef.current.getMap().getLayer(`pg-nafCircle`);

      if (typeof mapLayer !== "undefined") {
        // Remove map layer & source.
        mapRef.current
          .getMap()
          .removeLayer(`pg-nafCircle`)
          .removeLayer(`ls-nafCircle`)
          .removeSource("nafCircle");
      }

      if (nafRadius != null) {
        mapRef.current.getMap().addSource("nafCircle", {
          type: "geojson",
          data: turf.featureCollection([
            turf.circle(clientPosition, nafRadius),
          ]),
        });

        mapRef.current.getMap().addLayer({
          id: `pg-nafCircle`,
          type: "fill",
          source: "nafCircle",
          layout: {},
          paint: {
            "fill-color": "green",
            "fill-opacity": 0.1,
          },
        });

        mapRef.current.getMap().addLayer({
          id: `ls-nafCircle`,
          type: "line",
          source: "nafCircle",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "green",
            "line-width": 3,
          },
        });
      }
    }
  }, [nafRadius]);

  async function incrementNafRadiusAndRecalculate(increment) {
    Promise.all([
      dispatch(incrementNafRadius(increment)),
      dispatch(removeNafAndExternalPartners()),
      dispatch(setFoundInstallers(null)),
      dispatch(setSearchStep(7)),
    ]).then(() => {
      fetchNafPartners(nafRadius);
    });
  }

  async function fetchPentairPartners() {
    var foundPartnersAsInstallers = await axios
      .get(
        "/api/v1/pentair?field_dl_coordinates_proximity_center[coordinates][lat]=" +
          clientPosition[1] +
          "&field_dl_coordinates_proximity_center[coordinates][lng]=" +
          clientPosition[0]
      )
      .then(async (res) => {
        const $ = cheerio.load(res.data);

        const array = await Promise.all(
          $(".result-list > li").map(async (_, found) => {
            const $found = $(found);
            const name = $found
              .find(".views-field-title > .field-content")
              .text();
            const address = $found
              .find(".views-field-field-dl-address > .field-content")
              .text();
            const phone = $found
              .find(".views-field-field-dl-phone > .field-content")
              .text();

            var center = [0.0, 0.0];

            try {
              let request =
                "https://api-adresse.data.gouv.fr/search/?q=" + address;
              const response = await fetch(request);
              const geojson = await response.json();
              for (let feature of geojson.features) {
                center = [
                  feature.geometry.coordinates[0],
                  feature.geometry.coordinates[1],
                ];
              }
            } catch (e) {
              console.error(`Failed to forwardGeocode with error: ${e}`);
            }

            return {
              siret: name, // SIRENE API is limited to 30 calls per min, so we don't fetch SIRET for now for SOS & PENTAIR
              // (not needed if we don't plan to ingest them into the CRM from Foundy, for now)
              name: name,
              partner_longitude: center[0],
              partner_latitude: center[1],
              address: address,
              phone: phone,
              time: 0,
              origin: "pentair",
            };
          })
        );

        await dispatch(addPartners(array));
        calculateClient(array);
      });
  }

  async function fetchSosBricolagePartners() {
    var array = sosPartners.map((elem) => {
      var name;
      if (elem.email) {
        let nameSplit = elem.email.split("@")[0].split(".");
        name =
          nameSplit[0].charAt(0).toUpperCase() +
          nameSplit[0].substring(1) +
          " " +
          nameSplit[1].toUpperCase();
      } else {
        name = elem.city;
      }

      return {
        siret: name, // SIRENE API is limited to 30 calls per min, so we don't fetch SIRET for now for SOS & PENTAIR
        // (not needed if we don't plan to ingest them into the CRM from Foundy, for now)
        name: name,
        partner_longitude: elem.locate.geolocLng,
        partner_latitude: elem.locate.geolocLat,
        phone: elem.phone,
        time: 0,
        address:
          elem.locate.address +
          ", " +
          elem.locate.postalCode +
          " " +
          elem.locate.city,
        origin: "sosbricolage",
      };
    });

    await dispatch(addPartners(array));
    calculateClient(array);
  }

  async function fetchNafPartners(radius) {
    await fetch(
      // async
      "/api/v1/partners/getDisabled"
    )
      .then((resp) => resp.json())
      .then((json) => dispatch(disablePartners(json)))
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line

    fetch(
      "https://recherche-entreprises.api.gouv.fr/near_point?lat=" +
        clientPosition[1] +
        "&long=" +
        clientPosition[0] +
        "&radius=" +
        radius +
        "&activite_principale=43.22A&page=1&per_page=25"
    )
      .then((resp) => resp.json())
      .then((json) => json.results)
      .then((json) =>
        json.map((item) => {
          return {
            // traitement à déporter côté backend et faire le call au backend ?
            siret: item.siege.siret,
            name: item.nom_complet.toUpperCase(),
            partner_longitude: item.siege.longitude,
            partner_latitude: item.siege.latitude,
            address: item.siege.adresse,
            time: 0,
            nombre_etablissements_ouverts: item.nombre_etablissements_ouverts,
            etat_administratif: item.etat_administratif,
          };
        })
      )
      .then((json) =>
        json.filter(
          (v) =>
            v.nombre_etablissements_ouverts >= 1 && v.etat_administratif == "A"
        )
      )
      .then((json) =>
        json.filter(
          (v) => v.partner_longitude != null && v.partner_latitude != null
        )
      )
      .then((json) =>
        json.filter(
          (v) =>
            partnersData.find(
              (p) => p.siret == v.siret && p.origin == "odoo"
            ) == null ||
            partnersData.find(
              (p) => p.siret == v.siret && p.origin == "colors"
            ) == null
        )
      )
      .then((json) =>
        json.map((v) => ({
          ...v,
          origin: "naf",
          installing: true,
          selling: true,
        }))
      )
      //.then(json => console.log(json))
      .then(
        async (
          json // this is done to calculate only new NAF
        ) => {
          if (disabledPartners != null) {
            json = [
              ...json.map((p) => {
                disabledPartners.map((dp) => {
                  if (dp.siret + "" == p.siret) {
                    p.installing = dp.installing;
                    p.selling = dp.selling;
                    p.installingRefusalExpirationDate =
                      dp.installingRefusalExpirationDate;
                  }
                });

                return p;
              }),
            ];
          }
          await dispatch(addPartners(json));
          calculateClient(json);
        }
      )
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
    fetch(
      "https://recherche-entreprises.api.gouv.fr/near_point?lat=" +
        clientPosition[1] +
        "&long=" +
        clientPosition[0] +
        "&radius=" +
        radius +
        "&activite_principale=43.22B&page=1&per_page=25"
    )
      .then((resp) => resp.json())
      .then((json) => json.results)
      .then((json) =>
        json.map((item) => {
          return {
            // traitement à déporter côté backend et faire le call au backend ?
            siret: item.siege.siret,
            name: item.nom_complet.toUpperCase(),
            partner_longitude: item.siege.longitude,
            partner_latitude: item.siege.latitude,
            address: item.siege.adresse,
            time: 0,
            nombre_etablissements_ouverts: item.nombre_etablissements_ouverts,
            etat_administratif: item.etat_administratif,
          };
        })
      )
      .then((json) =>
        json.filter(
          (v) =>
            v.nombre_etablissements_ouverts >= 1 && v.etat_administratif == "A"
        )
      )
      .then((json) =>
        json.filter(
          (v) => v.partner_longitude != null && v.partner_latitude != null
        )
      )
      .then((json) =>
        json.filter(
          (v) =>
            partnersData.find(
              (p) => p.siret == v.siret && p.origin == "odoo"
            ) == null ||
            partnersData.find(
              (p) => p.siret == v.siret && p.origin == "colors"
            ) == null
        )
      )
      .then((json) =>
        json.map((v) => ({
          ...v,
          origin: "naf",
          installing: true,
          selling: true,
        }))
      )
      //.then(json => console.log(json))
      .then(
        async (
          json // this is done to calculate only new NAF
        ) => {
          if (disabledPartners != null) {
            json = [
              ...json.map((p) => {
                disabledPartners.map((dp) => {
                  if (dp.siret + "" == p.siret) {
                    p.installing = dp.installing;
                    p.selling = dp.selling;
                    p.installingRefusalExpirationDate =
                      dp.installingRefusalExpirationDate;
                  }
                });

                return p;
              }),
            ];
          }
          await dispatch(addPartners(json));
          calculateClient(json);
        }
      )
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
  }

  React.useEffect(() => {
    if (selectedPartner == null) {
      dispatch(setSelectedPartnerComments(null));
      return;
    }

    /*if(selectedPartner.comments == null && selectedPartner.partner_longitude != null) {
            fetch("/api/v1/comment/getComments?siret=" + selectedPartner.siret, {
                method: "GET"
              })
                .then( async (response) => {
                  if(response.ok) {
                    let data = await response.json();
                    setSelectedPartner(selectedPartner => ({ ...selectedPartner, comments: data }));
                  } else {
                    setSelectedPartner(selectedPartner => ({ ...selectedPartner, comments: "{}" }));
                  }
            });
        }*/

    fetchAndSetSelectedPartnerComments();
  }, [selectedPartner]);

  const [selectedLayer, setSelectedLayer] = useState(null);
  const defaultTravelTime = 30;

  const TopBottomNavigationAction = styled(BottomNavigationAction)(
    ({ theme }) => ({
      "border-radius": "25px",
      "margin-right": "10px",
      "background-color": "rgb(242, 242, 242)",
    })
  );

  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const commentInputRef = useRef("");
  const exportMinInputRef = useRef("");

  const addComment = () => {
    if (commentInputRef.current.value == "") {
      return;
    }
    var details = {
      siret: selectedPartner.siret,
      content: commentInputRef.current.value,
    };

    fetch("/api/v1/comment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }).then(async (response) => {
      let data = await response.json();

      console.log(data);

      fetchAndSetSelectedPartnerComments();
    });
  };

  const steps = [
    { label: "Tous les Pros" },
    {
      label: "Pros. conventionnés",
      color:
        "linear-gradient(90deg, rgba(6,150,240,1) 0%, rgba(6,150,240,1) 39%, rgb(0, 208, 91) 49%, rgb(0, 208, 91) 100%)",
      tooltip: "Talent-France conventionné",
    },
    {
      label: "Contacts Pro",
      color: "rgb(12, 32, 146)",
      tooltip: "Aqua2000",
    },
    {
      label: "Pros. non conventionnés",
      color:
        "linear-gradient(90deg, rgba(6,150,240,1) 0%, rgba(6,150,240,1) 39%, rgba(203,1,0,1) 49%, rgba(203,1,0,1) 100%)",
      tooltip: "Talent-France non conventionné",
    },
    {
      label: "Prospects SosBricolage",
      color: "#fe6502",
      tooltip: "Pros issus du site SosBricolage",
    },
    {
      label: "Prospects Pentair",
      color:
        "linear-gradient(90deg, rgba(100,167,11,1) 0%, rgba(9,36,79,1) 100%)",
      tooltip: "Pros issus du site Pentair",
    },
    {
      label: "Prospects Pro",
      color: "#08d54c",
      tooltip: "Pros trouvés dans la base du gouv via leur NAF",
    },
  ];

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "60%",
    maxHeight: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    padding: "32px 40px",
  };

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const searchType = useSelector((state) => state.partners.searchType);
  const handleSearchTypeChange = (event) => {
    dispatch(setSearchType(event.target.value));
  };

  const conventionStatusColors = {
    true: "#00a448",
    false: "#e80000",
  };

  const originPriorityOrder = [
    "conventionne",
    "contactspro",
    "nonconventionne",
    "prospects",
  ];

  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}h ${m}`;
  };

  return (
    <div className="App">
      <Navbar option={option} />
      <Box display="flex" flexDirection="row">
        {selectedLayer == "heatmap" ? (
          <Grid item xs={12}>
            <Box ml={1} mt={4}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 220,
                  bgcolor: "background.paper",
                  maxHeight: "calc(90vh - 32px - 64px)",
                  overflow: "auto",
                }}
                subheader={<b>Réglages Heatmap</b>}
              >
                <ListItem>
                  <Slider
                    getAriaLabel={() => "Range dureté TH"}
                    value={heatmapRange}
                    onChange={handleHeatmapRangeChange}
                    valueLabelDisplay="auto"
                  />
                </ListItem>
                <ListItem>
                  <Slider
                    getAriaLabel={() => "Opacité"}
                    value={heatmapOpacity}
                    onChange={handleHeatmapOpacityChange}
                    valueLabelDisplay="auto"
                  />
                </ListItem>
                {roles == "[ADMIN]" &&
                (email == "contact@skavi.fr" ||
                  email == "t.gosselin@aqua2000.fr") ? (
                  <>
                    <ListItem>
                      <Button onClick={toggleCsvMode}>
                        Mode Export CSV (via dessin)
                      </Button>
                    </ListItem>
                    <ListItem>
                      <TextField
                        id="csv-export"
                        inputRef={exportMinInputRef}
                        label="Rayon en minutes"
                        multiline
                        rows={4}
                        fullWidth
                        defaultValue=""
                        variant="filled"
                      />
                      <Button onClick={exportCsvViaRayonMin}>
                        Export CSV (via rayon min)
                      </Button>
                    </ListItem>
                  </>
                ) : (
                  ""
                )}
                {roles == "[ADMIN]" && csvData != null ? (
                  <CSVDownload data={csvData} target="_blank" />
                ) : (
                  ""
                )}
              </List>
            </Box>
            <Box display="flex" justifyContent="flex-end" mr={1}>
              {commune && commune.length > 0 ? (
                <List
                  align="right"
                  sx={{
                    width: "100%",
                    maxWidth: 400,
                    bgcolor: "background.paper",
                    maxHeight: "calc(90vh - 32px - 64px - 54px)",
                    overflow: "scroll",
                  }}
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      <b>Informations sur une commune</b>
                    </ListSubheader>
                  }
                >
                  <Box>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="h6"
                        >
                          {commune[0].nom_commune +
                            " " +
                            commune[0].code_commune}
                        </Typography>
                      }
                    />
                    {commune.map((communeIndiv) => (
                      <ListItem>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Quartier —
                              </Typography>
                              {communeIndiv.nom_quartier != "-"
                                ? communeIndiv.nom_quartier
                                : "Non connu / tout"}
                              <br />
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Réseau utilisé —
                              </Typography>
                              {communeIndiv.nom_reseau}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  </Box>
                  <hr />
                  <ListItemText
                    primary={
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="h6"
                      >
                        Prélèvements associés
                      </Typography>
                    }
                  />
                  {prelevements.map((prelevement) => (
                    <ListItem>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Commune de prélèvement —
                            </Typography>
                            {prelevement.nom_commune}
                            <br />
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Installation en amont —
                            </Typography>
                            {prelevement.nom_installation_amont
                              ? prelevement.nom_installation_amont
                              : "N/A"}
                            <br />
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Date de prélèvement —
                            </Typography>
                            {prelevement.date_prelevement}
                            <br />
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Réseaux —
                            </Typography>
                            <h7 style={{ "font-size": "10px" }}>
                              {prelevement.reseaux.map((reseau) => (
                                <i>{reseau.nom} / </i>
                              ))}
                            </h7>
                            <br />
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Résultat TH —
                            </Typography>
                            {prelevement.resultat_numerique}
                            <Divider light />
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        ) : (
          ""
        )}

        {foundInstallers !== null &&
        foundInstallers.length == 0 &&
        calculating == false ? (
          <Grid item xs={4}>
            <Box mt={4} ml={1}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 420,
                  bgcolor: "background.paper",
                  maxHeight: "calc(90vh - 32px - 64px)",
                  overflow: "auto",
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <b>
                      Pas d'installateurs trouvés{" "}
                      {nafRadius ? ", Rayon NAF " + nafRadius + " km" : ""}
                    </b>
                    <br />
                    {nafRadius ? (
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            incrementNafRadiusAndRecalculate(1);
                          }}
                        >
                          <ListItemText
                            inset
                            primary="Rayon de recherche +1 km"
                          />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                      ""
                    )}
                  </ListSubheader>
                }
              ></List>
            </Box>
          </Grid>
        ) : (
          ""
        )}
        {foundInstallers !== null &&
        (foundInstallers.length > 0 ||
          (calculating == true && foundInstallers.length == 0)) ? ( // sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', position: 'relative' }}
          <Grid item xs={4}>
            <Box mt={3} ml={1}>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 430,
                  bgcolor: "background.paper",
                  maxHeight: "calc(90vh - 32px - 64px)",
                  overflow: "auto",
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Installateurs trouvés (
                    <Link
                      onClick={() => {
                        dispatch(setFoundInstallers([]));
                      }}
                      href="#"
                      variant="body2"
                    >
                      {"passer une étape"}
                    </Link>
                    )
                    {nafRadius
                      ? <br /> && " Rayon NAF " + nafRadius + " km"
                      : ""}
                    <IconButton
                      onClick={() => {
                        dispatch(setFoundInstallers(null));
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListSubheader>
                }
              >
                {calculating == true && foundInstallers != null ? (
                  <ListItem>
                    <ListItemText
                      inset
                      primary="Calculs d'itinéraires toujours en cours..."
                    />
                    <br />
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  </ListItem>
                ) : (
                  ""
                )}
                {nafRadius ? (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        incrementNafRadiusAndRecalculate(1);
                      }}
                    >
                      <ListItemText inset primary="Rayon de recherche +1 km" />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  ""
                )}

                {foundInstallers.filter(
                  (elem) => elem.costInstallateur.time != 0
                ).length > 0 ? (
                  <Box sx={{ maxWidth: "400" }}>
                    <Stepper
                      sx={{ cursor: "pointer" }}
                      activeStep={searchStep - 1}
                      alternativeLabel
                    >
                      {steps.slice(0, 1).map((step) => (
                        <Step
                          key={step.label}
                          onClick={() => {
                            dispatch(async () => {
                              setPage(1);
                              await dispatch(removeNafAndExternalPartners());
                              await dispatch(setNafRadius(null));
                              await dispatch(
                                setSearchStep(steps.indexOf(step))
                              );
                              dispatch(setFoundInstallers([]));
                            });
                          }}
                        >
                          <StepLabel
                            cursor="pointer"
                            sx={{
                              ".MuiStepLabel-iconContainer.Mui-active svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-active":
                                {
                                  border: "5px double #bdbdbd",
                                  borderRadius: "17px",
                                  fontSize: "35px",
                                },
                            }}
                          >
                            {step.label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>

                    <Stepper
                      sx={{ cursor: "pointer" }}
                      activeStep={searchStep - 1}
                      alternativeLabel
                    >
                      {steps.slice(1, -3).map((step, index) => (
                        <Tooltip title={step.tooltip} aria-label={step.label}>
                          <Step
                            key={step.label}
                            index={index + 1}
                            onClick={() => {
                              dispatch(async () => {
                                setPage(1);
                                await dispatch(removeNafAndExternalPartners());
                                await dispatch(setNafRadius(null));
                                await dispatch(
                                  setSearchStep(steps.indexOf(step))
                                );
                                dispatch(setFoundInstallers([]));
                              });
                            }}
                          >
                            <StepLabel
                              cursor="pointer"
                              sx={{
                                ".MuiStepLabel-iconContainer.Mui-active svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-active":
                                  {
                                    border: "5px double #bdbdbd",
                                    borderRadius: "17px",
                                    fontSize: "35px",
                                  },
                                "&.Mui-disabled .MuiStepLabel-iconContainer > .MuiSvgIcon-root":
                                  {
                                    background: step.color,
                                    color: "#0000",
                                    borderRadius: "15px",
                                  },
                              }}
                            >
                              {step.label}
                            </StepLabel>
                          </Step>
                        </Tooltip>
                      ))}
                    </Stepper>

                    <Stepper
                      sx={{ cursor: "pointer" }}
                      activeStep={searchStep - 1}
                      alternativeLabel
                    >
                      {steps.slice(-3).map((step, index) => (
                        <Tooltip title={step.tooltip} aria-label={step.label}>
                          <Step
                            key={step.label}
                            index={index + 4}
                            onClick={() => {
                              dispatch(async () => {
                                setPage(1);
                                await dispatch(removeNafAndExternalPartners());
                                await dispatch(setNafRadius(null));
                                await dispatch(
                                  setSearchStep(steps.indexOf(step))
                                );
                                dispatch(setFoundInstallers([]));
                              });
                            }}
                          >
                            <StepLabel
                              cursor="pointer"
                              sx={{
                                ".MuiStepLabel-iconContainer.Mui-active svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-active":
                                  {
                                    border: "5px double #bdbdbd",
                                    borderRadius: "17px",
                                    fontSize: "35px",
                                  },
                                "&.Mui-disabled .MuiStepLabel-iconContainer > .MuiSvgIcon-root":
                                  {
                                    background: step.color,
                                    color: "#0000",
                                    borderRadius: "15px",
                                  },
                              }}
                            >
                              {step.label}
                            </StepLabel>
                          </Step>
                        </Tooltip>
                      ))}
                    </Stepper>
                  </Box>
                ) : (
                  ""
                )}

                {foundInstallers
                  .slice()
                  //.sort((a, b) => a.costInstallateur.time > b.costInstallateur.time ? 1 : -1)
                  .sort((a, b) => {
                    var aValue = 0;
                    var bValue = 0;

                    var aConventionne;
                    var aOdooEquivalent = partnersData.find(
                      (p) =>
                        p.siret == a.pointInstallateur.properties.siret &&
                        p.origin == "odoo"
                    );
                    var aColorsEquivalent = partnersData.find(
                      (p) =>
                        p.siret == a.pointInstallateur.properties.siret &&
                        p.origin == "colors"
                    );

                    if (aOdooEquivalent)
                      aConventionne = aOdooEquivalent.conventionne;
                    else if (a.pointInstallateur.properties.origin == "odoo")
                      conventionne =
                        a.pointInstallateur.properties.conventionne;

                    var bConventionne;
                    var bOdooEquivalent = partnersData.find(
                      (p) =>
                        p.siret == b.pointInstallateur.properties.siret &&
                        p.origin == "odoo"
                    );
                    var bColorsEquivalent = partnersData.find(
                      (p) =>
                        p.siret == b.pointInstallateur.properties.siret &&
                        p.origin == "colors"
                    );

                    if (bOdooEquivalent)
                      bConventionne = bOdooEquivalent.conventionne;
                    else if (b.pointInstallateur.properties.origin == "odoo")
                      conventionne =
                        b.pointInstallateur.properties.conventionne;

                    if (aColorsEquivalent) aValue = 0.75;
                    if (bColorsEquivalent) bValue = 0.75;
                    if (aOdooEquivalent) aValue = 0.5;
                    if (bOdooEquivalent) bValue = 0.5;
                    if (aOdooEquivalent && aConventionne == true) aValue = 1;
                    if (bOdooEquivalent && bConventionne == true) bValue = 1;
                    if (!aColorsEquivalent && !aOdooEquivalent) aValue = 0.25;
                    if (!bColorsEquivalent && !bOdooEquivalent) bValue = 0.25;

                    //if(a.costInstallateur && a.pointInstallateur.properties.time < a.costInstallateur.time) aValue -= 0.2;
                    //if(b.costInstallateur && b.pointInstallateur.properties.time < b.costInstallateur.time) aValue -= 0.2;

                    if (aValue == bValue)
                      return a.costInstallateur.time > b.costInstallateur.time
                        ? 1
                        : -1;
                    return aValue / a.costInstallateur.time <
                      bValue / b.costInstallateur.time
                      ? 1
                      : -1;
                  })
                  .slice(Math.floor(page * 3) - 3, Math.floor(page * 3))
                  .map((installateur) => {
                    var conventionne;
                    var odooEquivalent = partnersData.find(
                      (p) =>
                        p.siret ==
                          installateur.pointInstallateur.properties.siret &&
                        p.origin == "odoo"
                    );
                    var colorsEquivalent = partnersData.find(
                      (p) =>
                        p.siret ==
                          installateur.pointInstallateur.properties.siret &&
                        p.origin == "colors"
                    );

                    if (odooEquivalent)
                      conventionne = odooEquivalent.conventionne;
                    else if (
                      installateur.pointInstallateur.properties.origin == "odoo"
                    )
                      conventionne =
                        installateur.pointInstallateur.properties.conventionne;

                    return (
                      <ListItem>
                        <ListItemText
                          id={
                            "installTrouve_" +
                            installateur.pointInstallateur.properties.siret
                          }
                          primary={
                            <Box display="flex">
                              <Typography
                                sx={{
                                  display: "inline",
                                  cursor: "pointer",
                                  mr: 1,
                                  fontWeight:
                                    !selectedPartner ||
                                    (selectedPartner &&
                                      installateur.pointInstallateur.properties
                                        .siret != selectedPartner.siret)
                                      ? 400
                                      : 600,
                                }}
                                component="span"
                                variant="h6"
                                color={
                                  (selectedLayer == "installers" &&
                                    installateur.pointInstallateur.properties
                                      .installing == false) ||
                                  (selectedLayer == "prospects" &&
                                    installateur.pointInstallateur.properties
                                      .selling == false)
                                    ? "text.disabled"
                                    : !selectedPartner ||
                                      (selectedPartner &&
                                        installateur.pointInstallateur
                                          .properties.siret !=
                                          selectedPartner.siret)
                                    ? "text.primary"
                                    : "#ff00a7"
                                }
                                onClick={function (e) {
                                  if (
                                    !selectedPartner ||
                                    (selectedPartner &&
                                      installateur.pointInstallateur.properties
                                        .siret != selectedPartner.siret)
                                  ) {
                                    Promise.all([
                                      dispatch(setSelectedPartner(null)),
                                    ]).then(() => {
                                      dispatch(
                                        setSelectedPartner(
                                          installateur.pointInstallateur
                                            .properties
                                        )
                                      );
                                    });
                                    var flyToPoint;
                                    if (clientPosition != null) {
                                      flyToPoint = [
                                        (installateur.pointInstallateur.geometry
                                          .coordinates[0] +
                                          clientPosition[0]) /
                                          2,
                                        (installateur.pointInstallateur.geometry
                                          .coordinates[1] +
                                          clientPosition[1]) /
                                          2,
                                      ];
                                    } else {
                                      flyToPoint =
                                        installateur.pointInstallateur.geometry
                                          .coordinates;
                                    }
                                    mapRef.current.getMap().flyTo({
                                      center: flyToPoint,
                                      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                                    });
                                  } else if (
                                    selectedPartner &&
                                    installateur.pointInstallateur.properties
                                      .siret == selectedPartner.siret
                                  ) {
                                    dispatch(setSelectedPartner(null));
                                  }
                                }}
                                //onMouseOut={function(e) { dispatch(setSelectedPartner(null)) }}
                              >
                                {installateur.pointInstallateur.properties.name}{" "}
                                {(selectedLayer == "installers" &&
                                  installateur.pointInstallateur.properties
                                    .installing == false) ||
                                (selectedLayer == "prospects" &&
                                  installateur.pointInstallateur.properties
                                    .selling == false)
                                  ? " (indisponible)"
                                  : ""}
                              </Typography>

                              {installateur.costInstallateur &&
                              installateur.pointInstallateur.properties.time <
                                installateur.costInstallateur.time ? (
                                <CarCrashIcon
                                  sx={{ color: "red", marginRight: "5px" }}
                                />
                              ) : (
                                ""
                              )}
                              {installateur.pointInstallateur.properties
                                .origin == "odoo" || odooEquivalent ? (
                                <Badge
                                  overlap="circular"
                                  sx={{ mb: "auto" }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  // Conventionné ou non
                                  badgeContent={
                                    <Chip
                                      sx={{
                                        backgroundColor:
                                          conventionStatusColors[
                                            conventionne.toString()
                                          ] ?? "#e80000;",
                                        height: "20px",
                                        "& .MuiChip-label": {
                                          pl: "5px",
                                          pr: "5px",
                                        },
                                        color: "rgb(255, 255, 255);",
                                      }}
                                      label={conventionne == true ? "C" : "NC"}
                                    />
                                  }
                                >
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      bgcolor: "#0696f0",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    T
                                  </Avatar>
                                </Badge>
                              ) : (
                                ""
                              )}
                              {installateur.pointInstallateur.properties
                                .origin == "colors" || colorsEquivalent ? (
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    bgcolor: "#0c2092",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  A
                                </Avatar>
                              ) : (
                                ""
                              )}
                              {installateur.pointInstallateur.properties
                                .origin == "naf" ? (
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    bgcolor: "#08d54c",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  N
                                </Avatar>
                              ) : (
                                ""
                              )}
                              {installateur.pointInstallateur.properties
                                .origin == "sosbricolage" ? (
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    bgcolor: "#fe6502",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  SB
                                </Avatar>
                              ) : (
                                ""
                              )}
                              {installateur.pointInstallateur.properties
                                .origin == "pentair" ? (
                                <Avatar
                                  variant="rounded"
                                  sx={{
                                    background:
                                      "linear-gradient(90deg, rgba(100,167,11,1) 0%, rgba(9,36,79,1) 100%)",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  P
                                </Avatar>
                              ) : (
                                ""
                              )}
                            </Box>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                TEMPS MAX —
                              </Typography>
                              <Stack
                                sx={{ display: "inline-flex" }}
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <RunningWithErrorsIcon />
                                <Typography
                                  sx={{
                                    display: "inline",
                                    color: "black",
                                    fontWeight: 600,
                                  }}
                                >
                                  {installateur.pointInstallateur.properties
                                    .time >= 60
                                    ? convertMinsToHrsMins(
                                        installateur.pointInstallateur
                                          .properties.time
                                      )
                                    : installateur.pointInstallateur.properties
                                        .time}{" "}
                                  min
                                </Typography>
                              </Stack>
                              <br />
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                TPS ESTIMÉ —
                              </Typography>
                              <Stack
                                sx={{ display: "inline-flex" }}
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <ScheduleSendIcon />
                                <Typography
                                  sx={{
                                    display: "inline",
                                    color: "black",
                                    fontWeight: 600,
                                  }}
                                >
                                  {installateur.costInstallateur
                                    ? installateur.costInstallateur.time >= 60
                                      ? convertMinsToHrsMins(
                                          installateur.costInstallateur.time
                                        )
                                      : installateur.costInstallateur.time
                                    : "Inconnu/erreur"}{" "}
                                  min
                                </Typography>
                                {installateur.costInstallateur &&
                                installateur.pointInstallateur.properties.time <
                                  installateur.costInstallateur.time ? (
                                  <CloseRoundedIcon sx={{ color: "red" }} />
                                ) : (
                                  ""
                                )}
                              </Stack>
                              <br />
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                DISTANCE ESTIMÉE —
                              </Typography>
                              <Stack
                                sx={{ display: "inline-flex" }}
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <DriveEtaIcon
                                  color={
                                    installateur.costInstallateur
                                      ? installateur.costInstallateur
                                          .distance <= 10
                                        ? "#0f7500d4"
                                        : installateur.costInstallateur
                                            .distance <= 30
                                        ? "#b59000d4"
                                        : "#ff0000d4"
                                      : "#000"
                                  }
                                />
                                <Typography
                                  sx={{ display: "inline", fontWeight: 600 }}
                                  color={
                                    installateur.costInstallateur
                                      ? installateur.costInstallateur
                                          .distance <= 10
                                        ? "#0f7500d4"
                                        : installateur.costInstallateur
                                            .distance <= 30
                                        ? "#b59000d4"
                                        : "#ff0000d4"
                                      : "#000"
                                  }
                                >
                                  {installateur.costInstallateur
                                    ? installateur.costInstallateur.distance
                                    : "Inconnu/erreur"}{" "}
                                  km
                                </Typography>
                              </Stack>
                              <br />

                              {(installateur.pointInstallateur.properties
                                .supplier_invoice_count !== undefined ||
                                colorsEquivalent) && (
                                <>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    POSES AQUA 2000 —
                                  </Typography>
                                  <Stack
                                    sx={{ display: "inline-flex" }}
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Avatar
                                      variant="rounded"
                                      sx={{
                                        bgcolor: "#0c2092",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      A
                                    </Avatar>
                                    <Typography
                                      sx={{
                                        display: "inline",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {installateur.pointInstallateur.properties
                                        .supplier_invoice_count !== undefined ||
                                      (colorsEquivalent &&
                                        colorsEquivalent.supplier_invoice_count !==
                                          undefined)
                                        ? installateur.pointInstallateur
                                            .properties
                                            .supplier_invoice_count ||
                                          colorsEquivalent?.supplier_invoice_count
                                        : "Inconnu/erreur"}{" "}
                                    </Typography>
                                  </Stack>
                                </>
                              )}
                              <br />
                              {(installateur.pointInstallateur.properties
                                .x_studio_nb_adous_parc !== undefined ||
                                odooEquivalent) && (
                                <>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    ADOUS TALENT —
                                  </Typography>
                                  <Stack
                                    sx={{ display: "inline-flex" }}
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Avatar
                                      variant="rounded"
                                      sx={{
                                        bgcolor: "#0696f0",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      T
                                    </Avatar>
                                    <Typography
                                      sx={{
                                        display: "inline",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {installateur.pointInstallateur.properties
                                        .x_studio_nb_adous_parc !== undefined ||
                                      (odooEquivalent &&
                                        odooEquivalent.x_studio_nb_adous_parc !==
                                          undefined)
                                        ? installateur.pointInstallateur
                                            .properties
                                            .x_studio_nb_adous_parc ||
                                          odooEquivalent?.x_studio_nb_adous_parc
                                        : "Inconnu/erreur"}{" "}
                                    </Typography>
                                  </Stack>
                                </>
                              )}
                              <br />
                              <Accordion
                                sx={{
                                  boxShadow: "none",
                                  "MuiButtonBase-root": { padding: "0" },
                                }}
                                expanded={
                                  expanded ===
                                  installateur.pointInstallateur.properties
                                    .siret
                                }
                                onChange={handleAccordionChange(
                                  installateur.pointInstallateur.properties
                                    .siret
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography
                                    sx={{ width: "auto", flexShrink: 0 }}
                                  >
                                    Plus d'informations
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    SIRET —
                                  </Typography>
                                  {
                                    installateur.pointInstallateur.properties
                                      .siret
                                  }
                                  <br />
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    ADRESSE —
                                  </Typography>
                                  {
                                    installateur.pointInstallateur.properties
                                      .address
                                  }
                                  <br />
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    NUMERO DE TEL —
                                  </Typography>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    fontWeight="900"
                                  >
                                    {installateur.pointInstallateur.properties
                                      .phone
                                      ? installateur.pointInstallateur
                                          .properties.phone
                                      : colorsEquivalent &&
                                        colorsEquivalent.phone
                                      ? colorsEquivalent.phone
                                      : odooEquivalent && odooEquivalent.phone
                                      ? odooEquivalent.phone
                                      : "Non connu"}
                                  </Typography>

                                  <br />
                                  <Button
                                    size="small"
                                    target="_blank"
                                    href={
                                      "https://www.google.com/search?q=" +
                                      installateur.pointInstallateur.properties
                                        .name +
                                      " " +
                                      installateur.pointInstallateur.properties
                                        .address
                                    }
                                  >
                                    <b>
                                      Infos Google{" "}
                                      <img
                                        src={GoogleLogo}
                                        style={{ height: "10px" }}
                                      />
                                    </b>
                                  </Button>
                                  <Button
                                    size="small"
                                    target="_blank"
                                    href={
                                      "https://www.pagesjaunes.fr/annuaire/chercherlespros?quoiqui=" +
                                      installateur.pointInstallateur.properties
                                        .name +
                                      "&ou=" +
                                      installateur.pointInstallateur.properties.address
                                        .replace(",", "")
                                        .replace("France", "")
                                        .split(" ")
                                        .slice(-1)
                                        .join(" ") +
                                      "&univers=pagesjaunes&idOu="
                                    }
                                  >
                                    <b>
                                      Infos PagesJaunes{" "}
                                      <img
                                        src={PagesJaunesLogo}
                                        style={{ height: "10px" }}
                                      />
                                    </b>
                                  </Button>
                                  <br />
                                  {installateur.pointInstallateur.properties
                                    .origin == "colors" ? (
                                    <Button
                                      size="small"
                                      target="_blank"
                                      href={
                                        "https://aqua2000.odoo.com/web#id=" +
                                        installateur.pointInstallateur
                                          .properties.id +
                                        "&cids=1&menu_id=217&model=res.partner&view_type=form"
                                      }
                                    >
                                      <b>
                                        Lecture fiche CRM AQUA{" "}
                                        <Avatar
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "#0c2092",
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-flex",
                                            fontSize: "13px",
                                          }}
                                        >
                                          A
                                        </Avatar>
                                      </b>
                                    </Button>
                                  ) : (
                                    ""
                                  )}

                                  {installateur.pointInstallateur.properties
                                    .origin != "colors" &&
                                  colorsEquivalent != null ? (
                                    <Button
                                      size="small"
                                      target="_blank"
                                      href={
                                        "https://aqua2000.odoo.com/web#id=" +
                                        colorsEquivalent.id +
                                        "&cids=1&menu_id=217&model=res.partner&view_type=form"
                                      }
                                    >
                                      <b>
                                        Lecture fiche CRM AQUA{" "}
                                        <Avatar
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "#0c2092",
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-flex",
                                            fontSize: "13px",
                                          }}
                                        >
                                          A
                                        </Avatar>
                                      </b>
                                    </Button>
                                  ) : (
                                    ""
                                  )}

                                  {installateur.pointInstallateur.properties
                                    .origin == "odoo" ? (
                                    <Button
                                      size="small"
                                      target="_blank"
                                      href={
                                        "https://talentfrance-odoo.odoo.com/web#id=" +
                                        installateur.pointInstallateur
                                          .properties.id +
                                        "&cids=1&menu_id=217&action=321&model=res.partner&view_type=form"
                                      }
                                    >
                                      <b>
                                        Lecture fiche CRM TALENT{" "}
                                        <Avatar
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "#0696f0",
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-flex",
                                            fontSize: "13px",
                                          }}
                                        >
                                          T
                                        </Avatar>
                                      </b>
                                    </Button>
                                  ) : (
                                    ""
                                  )}

                                  {installateur.pointInstallateur.properties
                                    .origin != "odoo" &&
                                  odooEquivalent != null ? (
                                    <Button
                                      size="small"
                                      target="_blank"
                                      href={
                                        "https://talentfrance-odoo.odoo.com/web#id=" +
                                        odooEquivalent.id +
                                        "&cids=1&menu_id=217&action=321&model=res.partner&view_type=form"
                                      }
                                    >
                                      <b>
                                        Lecture fiche CRM TALENT{" "}
                                        <Avatar
                                          variant="rounded"
                                          sx={{
                                            bgcolor: "#0696f0",
                                            width: "16px",
                                            height: "16px",
                                            display: "inline-flex",
                                            fontSize: "13px",
                                          }}
                                        >
                                          T
                                        </Avatar>
                                      </b>
                                    </Button>
                                  ) : (
                                    ""
                                  )}

                                  {installateur.pointInstallateur.properties
                                    .origin == "naf" ? (
                                    <Box>
                                      {partnersData.find(
                                        (p) =>
                                          p.siret ==
                                            installateur.pointInstallateur
                                              .properties.siret &&
                                          p.origin == "odoo"
                                      ) == null ? (
                                        <Button
                                          size="small"
                                          target="_blank"
                                          onClick={() => {
                                            createOdooPartner(
                                              installateur.pointInstallateur
                                                .properties
                                            );
                                            dispatch(
                                              removeFoundInstaller(
                                                installateur.pointInstallateur
                                                  .properties
                                              )
                                            );
                                            dispatch(
                                              setPartners(
                                                partnersData.filter(
                                                  (partner) =>
                                                    partner.siret !=
                                                      installateur
                                                        .pointInstallateur
                                                        .properties.siret &&
                                                    partner.origin == "naf"
                                                )
                                              )
                                            );
                                          }}
                                        >
                                          <b>
                                            Ajout au CRM ODOO{" "}
                                            <Avatar
                                              variant="rounded"
                                              sx={{
                                                bgcolor: "#0696f0",
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-flex",
                                                fontSize: "13px",
                                              }}
                                            >
                                              T
                                            </Avatar>
                                          </b>
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                      {roles != "[TALENT_EXTERNE]" &&
                                      partnersData.find(
                                        (p) =>
                                          p.siret ==
                                            installateur.pointInstallateur
                                              .properties.siret &&
                                          p.origin == "colors"
                                      ) == null
                                        ? /*<Button
                                          size="small"
                                          target="_blank"
                                          onClick={() => {
                                            createColorsPartner(
                                              installateur.pointInstallateur
                                                .properties
                                            );
                                            dispatch(
                                              removeFoundInstaller(
                                                installateur.pointInstallateur
                                                  .properties
                                              )
                                            );
                                            dispatch(
                                              setPartners(
                                                partnersData.filter(
                                                  (partner) =>
                                                    partner.siret !=
                                                      installateur
                                                        .pointInstallateur
                                                        .properties.siret &&
                                                    partner.origin == "naf"
                                                )
                                              )
                                            );
                                          }}
                                        >
                                          <b>
                                            Ajout au CRM COLORS{" "}
                                            <Avatar
                                              variant="rounded"
                                              sx={{
                                                bgcolor: "#0c2092",
                                                width: "16px",
                                                height: "16px",
                                                display: "inline-flex",
                                                fontSize: "13px",
                                              }}
                                            >
                                              A
                                            </Avatar>
                                          </b>
                                        </Button>*/ ""
                                        : ""}
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </React.Fragment>
                          }
                        />
                        <Switch
                          edge="end"
                          onChange={function (e) {
                            handleToggle(
                              installateur.pointInstallateur.properties.siret
                            );
                            toggleContoursIsochroneInstallateur(installateur);
                          }}
                          //checked={checked.indexOf(installateur.pointInstallateur.properties.siret) !== -1}
                          inputProps={{
                            "aria-labelledby":
                              "installTrouve_" +
                              installateur.pointInstallateur.properties.siret,
                          }}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
            <Stack spacing={2} bottom={25} position="absolute">
              <Typography>
                Page: {page} -{" "}
                <Link
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    mapRef.current
                      .getMap()
                      .flyTo({ center: clientPosition, essential: true });
                  }}
                >
                  Recentrer
                </Link>
              </Typography>
              <Pagination
                count={Math.ceil(foundInstallers.length / 3)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        ) : null}
        <Map
          mapLib={maplibregl}
          ref={mapRef}
          onLoad={onMapLoad}
          initialViewState={{
            longitude: defaultLongitude ? defaultLongitude : 2.82,
            latitude: defaultLatitude ? defaultLatitude : 47.346,
            zoom: defaultLongitude ? 14 : 5,
          }}
          style={{ width: "100%", height: " calc(100vh - 77px)" }}
          mapStyle="https://aqua2000.fr/wp-tools/durete/standard.json"
          onDblClick={handleDoubleClick}
        >
          <Backdrop
            sx={{ color: "#fff" }}
            open={searching == true}
            onClick={() => {
              setSearching(false);
            }}
          >
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="h6" component="div">
                  Sélectionnez une adresse afin de lancer le calcul
                </Typography>
              </CardContent>
            </Card>
          </Backdrop>
          <Backdrop
            sx={{ color: "#fff" }}
            open={calculating == true && foundInstallers == null}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <NavigationControl position="top-left" />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  float: "right",
                  marginTop: "60px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ zIndex: 1, margin: "8px" }}>
                    Affichage points désactivés
                  </Typography>
                  <Switch
                    checked={showDisabledPartners}
                    color="secondary"
                    onClick={() => {
                      toggleShowDisabledPartners(showDisabledPartners);
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ zIndex: 1, margin: "8px" }}>
                    Affichage points{" "}
                    <Avatar
                      variant="rounded"
                      sx={{
                        bgcolor: "#0696f0",
                        width: "16px",
                        height: "16px",
                        display: "inline-flex",
                        fontSize: "13px",
                      }}
                    >
                      T
                    </Avatar>{" "}
                    conventionnés
                  </Typography>
                  <Switch
                    checked={showConventionnedPartners}
                    color="secondary"
                    onClick={() => {
                      toggleShowConventionnedPartners(
                        showConventionnedPartners
                      );
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ zIndex: 1, margin: "8px" }}>
                    Affichage points{" "}
                    <Avatar
                      variant="rounded"
                      sx={{
                        bgcolor: "#0696f0",
                        width: "16px",
                        height: "16px",
                        display: "inline-flex",
                        fontSize: "13px",
                      }}
                    >
                      T
                    </Avatar>{" "}
                    non conventionnés
                  </Typography>
                  <Switch
                    checked={showNonConventionnedPartners}
                    color="secondary"
                    onClick={() => {
                      toggleShowNonConventionnedPartners(
                        showNonConventionnedPartners
                      );
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ zIndex: 1, margin: "8px" }}>
                    Affichage points{" "}
                    <Avatar
                      variant="rounded"
                      sx={{
                        bgcolor: "#0c2092",
                        width: "16px",
                        height: "16px",
                        display: "inline-flex",
                        fontSize: "13px",
                      }}
                    >
                      A
                    </Avatar>{" "}
                  </Typography>
                  <Switch
                    checked={showAquaPartners}
                    color="secondary"
                    onClick={() => {
                      toggleShowAquaPartners(showAquaPartners);
                    }}
                  />
                </Box>
                <FormControl
                  variant="filled"
                  sx={{ float: "right", background: "#fff" }}
                >
                  <InputLabel id="select-partner-search-provider">
                    Chercher
                  </InputLabel>
                  <Select
                    labelId="partner-search-provider-label"
                    id="partner-search-provider"
                    value={searchType}
                    label="Search provider"
                    onChange={handleSearchTypeChange}
                  >
                    <MenuItem value={"address"}>Chercher adresse</MenuItem>
                    <MenuItem value={"partner"}>Chercher pro</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={3}>
                <BottomNavigation
                  showLabels
                  value={selectedLayer}
                  onChange={async (event, newSelectedLayer) => {
                    setSelectedLayer(newSelectedLayer);
                    if (
                      newSelectedLayer == "installers" ||
                      newSelectedLayer == "prospects"
                    ) {
                      dispatch(setPrelevements([]));
                      dispatch(setCommune(null));
                      fetchPartners(dispatch);
                    } else if (newSelectedLayer == "heatmap") {
                      await dispatch(setFoundInstallers(null));
                      await dispatch(removeNafAndExternalPartners());
                      dispatch(setNafRadius(null));

                      mapRef.current
                        .getMap()
                        .setStyle(
                          "https://aqua2000.fr/wp-tools/durete/standard.json"
                        );

                      dispatch(setFoundInstallers(null));
                      dispatch(setSelectedPartner(null));
                      dispatch(setSelectedPartnerComments(null));
                    }
                  }}
                >
                  <TopBottomNavigationAction
                    label="Heatmap"
                    icon={<MapIcon />}
                    value="heatmap"
                  />
                  {roles != "[TALENT_EXTERNE]" ? (
                    <TopBottomNavigationAction
                      label="Installateurs"
                      icon={<HandshakeIcon />}
                      value="installers"
                    />
                  ) : (
                    ""
                  )}
                  <TopBottomNavigationAction
                    label="Prospects"
                    icon={<HandshakeIcon />}
                    value="prospects"
                  />
                </BottomNavigation>
              </Box>
            </Grid>

            {selectedPartnerComments &&
              clientGeojson &&
              foundInstallers !== null &&
              foundInstallers.length > 0 && <Grid item xs={4}></Grid>}

            <Modal
              open={installingExpiralDateModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Possibilité de recontact
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Cet installateur sera-t-il disponible à une date
                      ultérieure ?
                    </FormLabel>
                    <RadioGroup
                      value={recontactValue}
                      onChange={handleRecontactValue}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Oui"
                      />
                      {recontactValue == "yes" ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label="Date d'expiration du refus"
                              inputFormat="MM/DD/YYYY"
                              value={recontactDate}
                              onChange={handleRecontactDate}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      ) : (
                        ""
                      )}
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="Non"
                      />
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </Box>
            </Modal>
            {selectedPartnerComments && selectedPartner
              ? getComments(
                  partnersData,
                  selectedPartner,
                  roles,
                  addComment,
                  defaultTravelTime,
                  togglePartnerSelling,
                  setSelectedPartner,
                  dispatch,
                  setPartners,
                  setInstallingExpiralDateModal,
                  createColorsPartner,
                  commentInputRef,
                  togglePartnerInstalling,
                  GoogleLogo,
                  PagesJaunesLogo,
                  createOdooPartner,
                  removeFoundInstaller,
                  selectedPartnerComments,
                  getRandomColor
                )
              : null}
          </Grid>

          {selectedLayer == "heatmap" && allData ? (
            <Source
              type="geojson"
              data={{
                type: allData.type,
                name: allData.name,
                crs: allData.crs,
                features: allData.features.filter(
                  (elemInside) =>
                    elemInside.properties.moyenneTh > heatmapRange[0] &&
                    elemInside.properties.moyenneTh < heatmapRange[1]
                ),
              }}
            >
              <Layer {...circleLayer} />
              <Layer {...textLayer} />
            </Source>
          ) : null}
          {selectedLayer == "heatmap2" ? (
            <Source type="geojson" data={allData}>
              <Layer
                {...{
                  type: "heatmap",
                  paint: {
                    "heatmap-intensity": 0.5,
                    "heatmap-radius": 10,
                    "heatmap-weight": [
                      "interpolate",
                      ["linear"],
                      ["*", 0.03, ["get", "valtraduite"]],
                      0,
                      0, // correspondance
                      0.1,
                      1,
                      0.3,
                      2,
                      0.5,
                      3,
                      0.7,
                      4,
                      1,
                      5,
                    ],
                    "heatmap-opacity": 1,
                  },
                }}
              />
              <Layer {...bgText} />
            </Source>
          ) : null}
          {(selectedLayer == "prospects" || selectedLayer == "installers") &&
            partnersData &&
            partnersData
              .filter((item) => !item.duplicate)
              .map((partner, i) => renderMarkers(partner, i))}
          {selectedPartner && (
            <Popup
              style={{ display: "none" }}
              offset={36}
              latitude={selectedPartner.partner_latitude}
              longitude={selectedPartner.partner_longitude}
              onClose={() => dispatch(setSelectedPartner(null))}
            >
              <div>
                {selectedPartner.name},{" "}
                {selectedPartner.phone ? selectedPartner.phone : "Non connu"}
              </div>
            </Popup>
          )}
        </Map>
      </Box>
    </div>
  );

  function renderMarkers(partner, i) {
    return (partner.origin !== "odoo" && partner.origin !== "colors") ||
      ((showDisabledPartners ||
        (selectedLayer == "installers" && partner.installing == true) ||
        (selectedLayer == "prospects" && partner.selling == true)) &&
        (partner.origin == "colors" ||
          (partner.origin == "odoo" &&
            ((!partner.conventionne && showNonConventionnedPartners) ||
              (partner.conventionne && showConventionnedPartners)))) &&
        (partner.origin == "odoo" ||
          (partner.origin == "colors" && showAquaPartners))) ? (
      <Marker
        key={partner.siret}
        latitude={partner.partner_latitude}
        longitude={partner.partner_longitude}
      >
        <div
          className={
            "marker " +
            (selectedPartner && selectedPartner.siret == partner.siret
              ? "selected-marker"
              : (selectedLayer == "installers" &&
                  partner.installing == false) ||
                (selectedLayer == "prospects" && partner.selling == false)
              ? "disabled-marker"
              : partner.origin
              ? "company-" + partner.origin + "-marker"
              : "company-marker")
          }
          onClick={function (e) {
            if (selectedPartner != null) {
              dispatch(setSelectedPartner(null));
            } else {
              dispatch(setSelectedPartner(partner));
            }
          }}
        >
          <span>
            {partner.costInstallateur &&
            partner.costInstallateur.distance &&
            (partner.costInstallateur.distance <= 50 ||
              selectedPartner?.siret == partner.siret) ? (
              <p
                class="marker-distance"
                style={{
                  "background-color":
                    partner.costInstallateur.distance <= 10
                      ? "#0f7500d4"
                      : partner.costInstallateur.distance <= 30
                      ? "#b59000d4"
                      : "#ff0000d4",
                }}
              >
                {partner.costInstallateur.distance} km
              </p>
            ) : (
              ""
            )}
            {partner.origin == "odoo" &&
              partner.x_studio_formation_realisee && (
                <WorkspacePremiumIcon
                  color="primary"
                  class="marker-formation"
                />
              )}
            {partner.origin == "odoo" && (
              <p
                class="marker-conventionne"
                style={{
                  "background-color":
                    conventionStatusColors[partner.conventionne.toString()] ??
                    "#e80000",
                }}
              >
                {partner.conventionne == true ? "C" : "NC"}
              </p>
            )}
          </span>
        </div>
      </Marker>
    ) : (
      ""
    );
  }

  function toggleShowDisabledPartners(showDisabledPartners) {
    dispatch(setShowDisabledPartners(!showDisabledPartners));
  }

  function toggleShowConventionnedPartners(showConventionnedPartners) {
    dispatch(setShowConventionnedPartners(!showConventionnedPartners));
  }

  function toggleShowNonConventionnedPartners(showNonConventionnedPartners) {
    dispatch(setShowNonConventionnedPartners(!showNonConventionnedPartners));
  }

  function toggleShowAquaPartners(showAquaPartners) {
    dispatch(setShowAquaPartners(!showAquaPartners));
  }

  function exportCsvViaRayonMin() {
    if (exportMinInputRef.current.value == "") {
      alert("Pas de temps en min renseigné !");
      return;
    }
    if (!doubleClickLng || !doubleClickLat) {
      alert("Double-cliquez au préalable sur votre cible !");
      return;
    }
    const options = [
      {
        time: Number(exportMinInputRef.current.value),
        color: "ff0000",
      },
    ];
    var costing = "auto";
    var lon = doubleClickLng;
    var lat = doubleClickLat;

    const jsonOption = {
      locations: [{ lat, lon }],
      costing,
      contours: options,
      polygons: true,
    };
    const url = `${isochroneUrl}/isochrone?json=${JSON.stringify(jsonOption)}`;
    axios.get(url).then((res) => {
      const geojson = res.data;

      var areaPolygonViaTurf = turf.polygon(
        geojson.features[0].geometry.coordinates
      );

      var json = allData.features
        .filter(
          (elemInside) =>
            elemInside.properties.moyenneTh > heatmapRange[0] &&
            elemInside.properties.moyenneTh < heatmapRange[1]
        )
        .filter((elemInside) => {
          return turf.booleanPointInPolygon(
            turf.point(elemInside.geometry.coordinates),
            areaPolygonViaTurf
          );
        })
        .map((elem) => {
          return {
            codeInsee: elem.properties.code_commune,
            codePostal: elem.properties.code_postal,
            commune: elem.properties.nom_commune,
            departement: elem.properties.nom_departement,
            population: elem.properties.population,
            moyenneTh: elem.properties.moyenneTh,
          };
        });
      //console.log(json);
      setCsvData(convertToCSV(json));
      console.log("ok csv data");
    });
  }

  function toggleCsvMode() {
    if (drawOpen) mapRef.current.removeControl(drawControl);
    else {
      mapRef.current.addControl(drawControl);
      mapRef.current.on("draw.create", updateArea);
      mapRef.current.on("draw.delete", updateArea);
      mapRef.current.on("draw.update", updateArea);
    }
    setDrawOpen(!drawOpen);
  }

  function convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
  }

  function updateArea(e) {
    const data = drawControl.getAll();
    if (data.features.length > 0) {
      console.log(data);
      var areaPolygonViaTurf = turf.polygon([
        data.features[0].geometry.coordinates[0],
      ]);
      //console.log(areaPolygonViaTurf);
      var json = allData.features
        .filter(
          (elemInside) =>
            elemInside.properties.moyenneTh > heatmapRange[0] &&
            elemInside.properties.moyenneTh < heatmapRange[1]
        )
        .filter((elemInside) => {
          return turf.booleanPointInPolygon(
            turf.point(elemInside.geometry.coordinates),
            areaPolygonViaTurf
          );
        })
        .map((elem) => {
          return {
            codeInsee: elem.properties.code_commune,
            codePostal: elem.properties.code_postal,
            commune: elem.properties.nom_commune,
            departement: elem.properties.nom_departement,
            population: elem.properties.population,
            moyenneTh: elem.properties.moyenneTh,
          };
        });
      //console.log(json);
      setCsvData(convertToCSV(json));
      console.log("ok csv data");
    }
  }

  function createOdooPartner(partner) {
    var details = {
      displayname: partner.name,
      siret: partner.siret,
      address: partner.address,
    };

    fetch("/api/v1/odoo/createPartner", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }).then(async (response) => {
      let data = await response;
      console.log(data);
    });
  }

  function createColorsPartner(partner) {
    // deprecated
    /*var details = {
      displayname: partner.name,
      siret: partner.siret,
      address: partner.address,
    };

    fetch("/api/v1/colors/createPartner", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    }).then(async (response) => {
      let data = await response;
      console.log(data);
    });*/
  }

  function handleRecontactValue(event) {
    setRecontactValue(event.target.value);
    if (event.target.value == "no") {
      togglePartnerInstalling(selectedPartner);
      setInstallingExpiralDateModal(false);
    }
  }

  function handleRecontactDate(value) {
    setRecontactDate(dayjs(value));
    togglePartnerInstallingWithExpirationDate(selectedPartner, dayjs(value));
    setInstallingExpiralDateModal(false);
  }

  function fetchAndSetSelectedPartnerComments() {
    fetch("/api/v1/comment/getComments?siret=" + selectedPartner.siret, {
      method: "GET",
    }).then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        dispatch(setSelectedPartnerComments(data));
      } else {
        dispatch(setSelectedPartnerComments(JSON.parse("[]")));
      }
    });
  }

  function togglePartnerSelling(partner) {
    changePartnerActivation(partner, "Selling", !partner.selling);
  }

  function togglePartnerInstalling(partner) {
    changePartnerActivation(partner, "Installing", !partner.installing);
  }

  function togglePartnerInstallingWithExpirationDate(partner, expirationDate) {
    changePartnerActivation(partner, "Installing", !partner.installing, {
      siret: partner.siret,
      expirationDate: expirationDate.format("YYYY-MM-DD HH:mm:ss"),
    });
  }

  function changePartnerActivation(
    partner,
    type,
    activation,
    details = { siret: partner.siret }
  ) {
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(
      "/api/v1/partners/" + (activation ? "activate" : "deactivate") + type,
      {
        method: "POST",
        body: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      }
    ).then(async (response) => {
      let data = await response.json();

      if (data) {
        // TODO renvoyer du json au lieu de juste true
        if (type == "Installing") {
          dispatch(
            setPartnerInstalling({
              siret: partner.siret,
              installing: activation,
              installingRefusalExpirationDate: details.expirationDate,
            })
          );
        } else if (type == "Selling") {
          dispatch(
            setPartnerSelling({ siret: partner.siret, selling: activation })
          );
        } else {
          console.log("Activation type not found");
        }
      } else {
        console.log("ERROR : you do not have enough rights to toggle");
      }
    });
  }

  function fetchFailedPartners(dispatch) {
    fetch("/api/v1/odoo/getPartnersWithEmptyGeocoding")
      .then((resp) => resp.json())
      .then((json) =>
        json.map((v) => ({
          ...v,
          origin: "odoo",
          installing: true,
          selling: true,
        }))
      )
      //.then(json => console.log(json))
      .then((json) => dispatch(addFailedPartners(json)))
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line

    if (roles != "[TALENT_EXTERNE]") {
      fetch("/api/v1/aqua/getPartnersWithEmptyGeocoding")
        .then((resp) => resp.json())
        .then((json) =>
          json.map((v) => ({
            ...v,
            origin: "colors",
            installing: true,
            selling: true,
          }))
        )
        //.then(json => console.log(json))
        .then((json) => dispatch(addFailedPartners(json)))
        .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
    }
  }

  async function fetchPartners(dispatch) {
    // async so even though getDisabled waits for ODOO & COLORS partners fetch, no frontend impact
    //console.log(partnersData.length);
    if (partnersData.length == 0) {
      var fetchingOdoo = await fetch("/api/v1/odoo/getPartners")
        .then((resp) => resp.json())
        .then((json) =>
          json.map((v) => ({
            ...v,
            origin: "odoo",
            installing: true,
            selling: true,
          }))
        )
        //.then(json => console.log(json))
        .then((json) => dispatch(addPartners(json)))
        .catch((err) => console.error("Could not load data", err));

      var fetchingColors =
        roles != "[TALENT_EXTERNE]"
          ? await fetch("/api/v1/aqua/getPartners")
              .then((resp) => resp.json())
              .then((json) =>
                json.map((v) => ({
                  ...v,
                  address: v.address,
                  origin: "colors",
                  installing: true,
                  selling: true,
                }))
              )
              //.then(json => console.log(json))
              .then((json) => dispatch(addPartnersAndAvoidDuplicates(json)))
              .catch((err) => console.error("Could not load data", err)) // eslint-disable-line
          : Promise.resolve();

      await Promise.all([
        fetchingOdoo, // TalentFrance
        fetchingColors, // AQUA2000 (conditional)
      ]);

      await fetch(
        // async
        "/api/v1/partners/getDisabled"
      )
        .then((resp) => resp.json())
        .then((json) => dispatch(disablePartners(json)))
        .catch((err) => console.error("Could not load data", err)); // eslint-disable-line

      return false;
    }
    return true;
  }

  async function calculateClient(partnersSelectedData) {
    if (clientGeojson !== null) {
      console.log("ok");
      /*if(selectedLayer == "prospects") {
                partnersSelectedData = partnersSelectedData.filter(partner => partner.installing != false);
            } else if (selectedLayer == "installers") {
                partnersSelectedData = partnersSelectedData.filter(partner => partner.selling != false);
            }*/
      var f = clientGeojson.features[0];

      //console.log(f);
      if (partnersSelectedData) {
        if (partnersSelectedData.length > 0) setCalculating(true);
        var partnersPoints = partnersSelectedData.map((partner, i) => {
          return turf.point(
            [partner.partner_longitude, partner.partner_latitude], // store long lat for calculations via turf.js
            structuredClone(partner) // store actual partner in point.properties to keep it's personal infos after treatment
          );
        });
        var clientPolygon = turf.polygon(f.geometry.coordinates);

        await Promise.all(
          partnersPoints.map(async (point) => {
            var isInPolygon = turf.booleanPointInPolygon(point, clientPolygon);
            if (isInPolygon) {
              if (!point.properties.time || point.properties.time == 0) {
                point.properties.time = defaultTravelTime;
              }
              /*const options = [
                            {
                                time: point.properties.time,
                                color: 'ff0000',
                            }
                        ];
                        var costing = "auto";
                        var lon = point.geometry.coordinates[0];
                        var lat = point.geometry.coordinates[1];
                        const jsonOption = {
                            locations: [{ lat, lon }],
                            costing,
                            contours: options,
                            polygons: true,
                        };
                        const url = `${isochroneUrl}/isochrone?json=${JSON.stringify(jsonOption)}`;
                        return await axios.get(url).then(res => {
                            const geojson = res.data;
                            
                            var installateurPolygon = turf.polygon(geojson.features[0].geometry.coordinates);
                            
                            var clientPositionTurf = turf.point(clientPosition);
                            
                            var installateurCanGoToClient = turf.booleanPointInPolygon(clientPositionTurf, installateurPolygon);
                            if(installateurCanGoToClient) {
                                // recap: actually, foundinstaller view is almost async. 
                                // it needs installateurCanGoToClient (ISOCHRONE) + timecost of client and it will show it instantly (so before, isochrone = instant view, now isochrone+timecost = instant view)
                                // as we are putting a "then" on a promise (return await of fetch in the function calculateTimeCost),
                                // they are coming asynchronously (we give the Promise order to calculate timecost into dispatch the found guy, meanwhile we continue to iterate and search isochrones of others)
                                // so we get 1 installer as soon as we calculate isochrone + timecost. I don't think it is useful to show installer directly after isochrone, it is better to wait for timecost maybe
                                calculateTimeCostForFoundInstaller(point).then(costInstallateur => {
                                    dispatch(addFoundInstallers({pointInstallateur: point, isochroneInstallateur: geojson.features[0], costInstallateur: costInstallateur}));
                                });
                                //dispatch(addFoundInstallers({pointInstallateur: point, isochroneInstallateur: geojson.features[0]}));
                                return true;//return point;
                            } else {
                                return false;
                            }
                        });*/
              return await calculateTimeCostForFoundInstaller(point).then(
                (costInstallateur) => {
                  dispatch(
                    updateTimeCostForInstaller({
                      siret: point.properties.siret,
                      costInstallateur: costInstallateur,
                    })
                  );
                  if (costInstallateur.time <= point.properties.time * 2) {
                    // Tolérance x2 - dépassement signalé par icône
                    return {
                      pointInstallateur: point,
                      isochroneInstallateur: null,
                      costInstallateur: costInstallateur,
                    };
                  } else {
                    return false;
                  }
                }
              );
            } else {
              return false;
            }
          })
        ).then((results) => {
          // commented stuff is if we want async time cost calculation in ONE request
          // actually we do otherwise in order to avoid calculation while searching objects in array to replace
          // we rather do one request PER found installer to get his time cost and add it directly before appending to the foundInstaller array so no search cost but more http
          var compatibleInstallerHasBeenFoundAndDispatched = false;
          var foundInstallersCoordinates = [];
          var foundInstallers = [];
          if (results) {
            results.forEach((result) => {
              if (result !== false) {
                compatibleInstallerHasBeenFoundAndDispatched = true;
                foundInstallers = [...foundInstallers, result];
                //foundInstallersCoordinates = [...foundInstallersCoordinates, {"lat": result.geometry.coordinates[1], "lon": result.geometry.coordinates[0]}]
              }
            });
          }
          if (compatibleInstallerHasBeenFoundAndDispatched == false) {
            dispatch(setFoundInstallers([]));
          } else {
            dispatch(setFoundInstallers(foundInstallers));
          }
        });
      }
    }
  }

  async function calculateTimeCostForFoundInstaller(point) {
    let installerCoordinates = {
      lat: point.geometry.coordinates[1],
      lon: point.geometry.coordinates[0],
    };
    let clientCoordinates = { lat: clientPosition[1], lon: clientPosition[0] };
    let jsonOption = {
      sources: [installerCoordinates],
      targets: [clientCoordinates],
      costing: "auto",
    };
    // calculate time in order to sort found installers
    let urlMatrix = `${isochroneUrl}/sources_to_targets?json=${JSON.stringify(
      jsonOption
    )}`;
    return await fetch(
      // async
      urlMatrix
    )
      .then((resp) => resp.json())
      .then((json) => {
        return {
          distance: Math.floor(json.sources_to_targets[0][0].distance),
          time: Math.floor(json.sources_to_targets[0][0].time / 60),
        };
      })
      .catch((err) => console.error("Could not load data", err)); // eslint-disable-line
  }

  async function toggleContoursIsochroneInstallateur(installateur) {
    var isochroneInstallateur = installateur.isochroneInstallateur;

    const sourceName = `isochrone-${installateur.pointInstallateur.properties.siret}`;
    var mapLayer = mapRef.current.getMap().getLayer(`pg-${sourceName}`);

    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      mapRef.current
        .getMap()
        .removeLayer(`pg-${sourceName}`)
        .removeLayer(`ls-${sourceName}`)
        .removeSource(sourceName);
      dispatch(setSelectedPartner(null));
    } else {
      if (installateur.isochroneInstallateur == null) {
        setCalculating(true);
        const options = [
          {
            time: installateur.pointInstallateur.properties.time,
            color: "ff0000",
          },
        ];
        var costing = "auto";
        var lon = installateur.pointInstallateur.geometry.coordinates[0];
        var lat = installateur.pointInstallateur.geometry.coordinates[1];
        const jsonOption = {
          locations: [{ lat, lon }],
          costing,
          contours: options,
          polygons: true,
        };
        const url = `${isochroneUrl}/isochrone?json=${JSON.stringify(
          jsonOption
        )}`;
        isochroneInstallateur = await axios.get(url).then((res) => {
          const geojson = res.data;
          return geojson.features[0];
        });
        dispatch(
          replaceFoundInstallerIsochrone({
            installateur: installateur,
            isochrone: isochroneInstallateur,
          })
        );
        setCalculating(false);
      }

      mapRef.current.getMap().addSource(sourceName, {
        type: "geojson",
        data: isochroneInstallateur,
      });

      mapRef.current.getMap().addLayer({
        id: `pg-${sourceName}`,
        type: "fill",
        source: sourceName,
        layout: {},
        paint: {
          "fill-color": isochroneInstallateur.properties.fillColor,
          "fill-opacity": 0.1,
        },
      });

      mapRef.current.getMap().addLayer({
        id: `ls-${sourceName}`,
        type: "line",
        source: sourceName,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": isochroneInstallateur.properties.fillColor,
          "line-width": 3,
        },
      });
      dispatch(setSelectedPartner(installateur.pointInstallateur.properties));
    }
  }
};

export default MainApp;
