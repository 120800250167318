/* eslint-disable react/jsx-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  List,
  ListSubheader,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Avatar,
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Chip,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const getComments = (
  partnersData,
  selectedPartner,
  roles,
  addComment,
  defaultTravelTime,
  togglePartnerSelling,
  setSelectedPartner,
  dispatch,
  setPartners,
  setInstallingExpiralDateModal,
  createColorsPartner,
  commentInputRef,
  togglePartnerInstalling,
  GoogleLogo,
  PagesJaunesLogo,
  createOdooPartner,
  removeFoundInstaller,
  selectedPartnerComments,
  getRandomColor
) => {
  var odooEquivalent = partnersData.find(
    (p) => p.siret == selectedPartner.siret && p.origin == "odoo"
  );
  var colorsEquivalent = partnersData.find(
    (p) => p.siret == selectedPartner.siret && p.origin == "colors"
  );
  return (
    //selectedPartner.comments ? (
    <Box mt={4} ml={0} sx={{ marginLeft: "auto", pr: "15px" }}>
      <List
        sx={{
          width: "100%",
          maxWidth: 500,
          bgcolor: "background.paper",
          maxHeight: "calc(70vh - 32px - 64px)",
          overflow: "auto",
        }}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            Installateur <b>{selectedPartner ? selectedPartner.name : ""}</b>
            <IconButton
              onClick={() => {
                dispatch(setSelectedPartner(null));
              }}
            >
              <CloseIcon />
            </IconButton>
          </ListSubheader>
        }
      >
        {(selectedPartner.origin == "odoo" &&
          selectedPartner.conventionne &&
          selectedPartner.conventionne == true) ||
        (odooEquivalent &&
          odooEquivalent.conventionne &&
          odooEquivalent.conventionne == true) ? (
          <ListItemText primary="2. Pro conventionné" />
        ) : (
          ""
        )}
        {selectedPartner.origin == "colors" || colorsEquivalent ? (
          <Typography>3. Contact Pro</Typography>
        ) : (
          ""
        )}
        {selectedPartner.origin == "odoo" || odooEquivalent ? (
          <Typography>4. Pro Non conventionné</Typography>
        ) : (
          ""
        )}
        {selectedPartner.origin == "naf" ? (
          <Typography>5. NAF / Prospect Potentiel</Typography>
        ) : (
          ""
        )}
        {selectedPartner.origin == "pentair" ? (
          <Typography>6. Pentair</Typography>
        ) : (
          ""
        )}
        {selectedPartner.origin == "sosbricolage" ? (
          <Typography>7. SosBricolage</Typography>
        ) : (
          ""
        )}
        <ListItem
          alignItems="flex-start"
          width="401px"
          sx={{
            backgroundColor: "#79aad74f;",
            overflow: "inherit",
          }}
        >
          <ListItemText
            primary="NumTél"
            secondary={
              <Typography variant="h6">
                {selectedPartner.phone ? selectedPartner.phone : "Non connu"}
              </Typography>
            }
            sx={{ paddingRight: "40px" }}
          />
          <ListItemText
            primary="Temps max / estimé"
            secondary={
              <>
                <Chip
                  style={{ fontWeight: 600, color: "#000" }}
                  label={
                    selectedPartner.time
                      ? selectedPartner.time + " min max"
                      : defaultTravelTime + " (défaut) min max"
                  }
                />
                {selectedPartner.hasOwnProperty("costInstallateur") &&
                selectedPartner.costInstallateur ? (
                  <Chip
                    style={{
                      fontWeight: 900,
                      fontSize: "15px",
                      color: "#fff",
                      "background-color":
                        selectedPartner.costInstallateur.distance <= 10
                          ? "#0f7500d4"
                          : selectedPartner.costInstallateur.distance <= 30
                          ? "#b59000d4"
                          : "#ff0000d4",
                    }}
                    label={
                      selectedPartner.costInstallateur.time +
                      " min / " +
                      selectedPartner.costInstallateur.distance +
                      " km estimé"
                    }
                  />
                ) : (
                  ""
                )}
              </>
            }
            sx={{ paddingRight: "25px", width: "210px" }}
          />
        </ListItem>
        <ListItem alignItems="flex-start" style={{ flexDirection: "column" }}>
          {(selectedPartner.supplier_invoice_count !== undefined ||
            (colorsEquivalent &&
              colorsEquivalent.supplier_invoice_count !== undefined)) && (
            <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  bgcolor: "#0c2092",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: "30px",
                  fontSize: "0.8rem",
                }}
              >
                A
              </Avatar>
              <Typography>
                <b>Poses Aqua 2000</b> :{" "}
                {selectedPartner.supplier_invoice_count ||
                  colorsEquivalent?.supplier_invoice_count}
              </Typography>
            </Box>
          )}
          {(selectedPartner.x_studio_nb_adous_parc !== undefined ||
            (odooEquivalent &&
              odooEquivalent.x_studio_nb_adous_parc !== undefined)) && (
            <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  bgcolor: "#0696f0",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: "30px",
                  fontSize: "0.8rem",
                }}
              >
                T
              </Avatar>
              <Typography>
                <b>Adous Talent</b> :{" "}
                {selectedPartner.x_studio_nb_adous_parc ||
                  odooEquivalent?.x_studio_nb_adous_parc}
              </Typography>
            </Box>
          )}
          <FormGroup>
            {roles == "[AQUA2000]" ||
            roles == "[ADMIN]" ||
            roles == "[USER]" /* roles == user , temporary */ ? (
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedPartner.installing}
                      color="primary"
                      onClick={() => {
                        selectedPartner.installing == false
                          ? togglePartnerInstalling(selectedPartner)
                          : setInstallingExpiralDateModal(true);
                      }}
                    />
                  }
                  label="OK pour poser"
                />
                {selectedPartner.installing == false ? (
                  <Chip
                    label={
                      selectedPartner.installingRefusalExpirationDate
                        ? "Indisponible jusqu'au " +
                          new Date(
                            selectedPartner.installingRefusalExpirationDate
                          ).toLocaleDateString()
                        : "Indisponible indéfiniment"
                    }
                  />
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
            {roles == "[TALENT]" ||
            roles == "[ADMIN]" ||
            roles == "[USER]" /* roles == user , temporary */ ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedPartner.selling}
                    color="primary"
                    onClick={() => {
                      togglePartnerSelling(selectedPartner);
                    }}
                  />
                }
                label="OK pour vendre"
              />
            ) : (
              ""
            )}
          </FormGroup>
        </ListItem>
        <ListItem alignItems="flex-start">
          <Button
            size="small"
            target="_blank"
            href={
              "https://www.google.com/search?q=" +
              selectedPartner.name +
              " " +
              selectedPartner.address
            }
          >
            <b>
              Infos Google <img src={GoogleLogo} style={{ height: "10px" }} />
            </b>
          </Button>
          <Button
            size="small"
            target="_blank"
            href={
              "https://www.pagesjaunes.fr/annuaire/chercherlespros?quoiqui=" +
              selectedPartner.name +
              "&ou=" +
              selectedPartner.address
                .replace(",", "")
                .replace("France", "")
                .split(" ")
                .slice(-1)
                .join(" ") +
              "&univers=pagesjaunes&idOu="
            }
          >
            <b>
              Infos PagesJaunes{" "}
              <img src={PagesJaunesLogo} style={{ height: "10px" }} />
            </b>
          </Button>
        </ListItem>
        <ListItem alignItems="flex-start">
          {selectedPartner.origin == "colors" ? (
            <Button
              size="small"
              target="_blank"
              href={
                "https://aqua2000.odoo.com/web#id=" +
                selectedPartner.id +
                "&cids=1&menu_id=217&model=res.partner&view_type=form"
              }
            >
              <b>
                Fiche CRM AQUA2000{" "}
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: "#0c2092",
                    width: "16px",
                    height: "16px",
                    display: "inline-flex",
                    fontSize: "13px",
                  }}
                >
                  A
                </Avatar>
              </b>
            </Button>
          ) : (
            ""
          )}
          {selectedPartner.origin != "colors" &&
          partnersData.find(
            (p) => p.siret == selectedPartner.siret && p.origin == "colors"
          ) != null ? (
            <Button
              size="small"
              target="_blank"
              href={
                "https://aqua2000.odoo.com/web#id=" +
                partnersData.find(
                  (p) =>
                    p.siret == selectedPartner.siret && p.origin == "colors"
                ).id +
                "&cids=1&menu_id=217&model=res.partner&view_type=form"
              }
            >
              <b>
                Fiche CRM AQUA2000{" "}
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: "#0c2092",
                    width: "16px",
                    height: "16px",
                    display: "inline-flex",
                    fontSize: "13px",
                  }}
                >
                  A
                </Avatar>
              </b>
            </Button>
          ) : (
            ""
          )}
          <br />
          {selectedPartner.origin == "odoo" ? (
            <Button
              size="small"
              target="_blank"
              href={
                "https://talentfrance-odoo.odoo.com/web#id=" +
                selectedPartner.id +
                "&cids=1&menu_id=217&action=321&model=res.partner&view_type=form"
              }
            >
              <b>
                Fiche CRM TALENT{" "}
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: "#0696f0",
                    width: "16px",
                    height: "16px",
                    display: "inline-flex",
                    fontSize: "13px",
                  }}
                >
                  T
                </Avatar>
              </b>
            </Button>
          ) : (
            ""
          )}
          {selectedPartner.origin != "odoo" &&
          partnersData.find(
            (p) => p.siret == selectedPartner.siret && p.origin == "odoo"
          ) != null ? (
            <Button
              size="small"
              target="_blank"
              href={
                "https://talentfrance-odoo.odoo.com/web#id=" +
                partnersData.find(
                  (p) => p.siret == selectedPartner.siret && p.origin == "odoo"
                ).id +
                "&cids=1&menu_id=217&action=321&model=res.partner&view_type=form"
              }
            >
              <b>
                Fiche CRM TALENT{" "}
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: "#0696f0",
                    width: "16px",
                    height: "16px",
                    display: "inline-flex",
                    fontSize: "13px",
                  }}
                >
                  T
                </Avatar>
              </b>
            </Button>
          ) : (
            ""
          )}

          {selectedPartner.origin == "naf" ? (
            <Box>
              {partnersData.find(
                (p) => p.siret == selectedPartner.siret && p.origin == "odoo"
              ) == null ? (
                <Button
                  size="small"
                  onClick={() => {
                    createOdooPartner(selectedPartner);
                    dispatch(removeFoundInstaller(selectedPartner));
                    dispatch(
                      setPartners(
                        partnersData.filter(
                          (partner) =>
                            partner.siret != selectedPartner.siret &&
                            partner.origin == "naf"
                        )
                      )
                    );
                  }}
                >
                  <b>
                    Ajout au CRM TALENT{" "}
                    <Avatar
                      variant="rounded"
                      sx={{
                        bgcolor: "#0696f0",
                        width: "16px",
                        height: "16px",
                        display: "inline-flex",
                        fontSize: "13px",
                      }}
                    >
                      T
                    </Avatar>
                  </b>
                </Button>
              ) : (
                ""
              )}
              <br />
              {roles != "[TALENT_EXTERNE]" &&
              partnersData.find(
                (p) => p.siret == selectedPartner.siret && p.origin == "colors"
              ) == null ? (
                <Button
                  size="small"
                  onClick={() => {
                    createColorsPartner(selectedPartner);
                    dispatch(removeFoundInstaller(selectedPartner));
                    dispatch(
                      setPartners(
                        partnersData.filter(
                          (partner) =>
                            partner.siret != selectedPartner.siret &&
                            partner.origin == "naf"
                        )
                      )
                    );
                  }}
                >
                  <b>
                    Ajout au CRM AQUA2000{" "}
                    <Avatar
                      variant="rounded"
                      sx={{
                        bgcolor: "#0c2092",
                        width: "16px",
                        height: "16px",
                        display: "inline-flex",
                        fontSize: "13px",
                      }}
                    >
                      A
                    </Avatar>
                  </b>
                </Button>
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
        </ListItem>
        {selectedPartnerComments.toReversed().map(
          (
            comment //selectedPartner.comments != "{}" && selectedPartner.comments.map(comment => (
          ) => (
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: getRandomColor(comment.user[0].firstName),
                  }}
                >
                  {Array.from(comment.user[0].firstName)[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  comment.user[0].firstName + " " + comment.user[0].lastName
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {new Date(comment.date).toLocaleString()}
                    </Typography>
                    — {comment.content}
                  </React.Fragment>
                }
              />
            </ListItem>
          )
        )}
        {selectedPartner.siret != 0 &&
        selectedPartner.siret != null &&
        (/\d{14}/g.test(selectedPartner.siret) ||
          /\d{9}/g.test(selectedPartner.siret)) ? (
          <>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemText
                id={"commentEcrivezNote"}
                primary={
                  <TextField
                    id="selected-partner-commenting"
                    inputRef={commentInputRef}
                    label="Ecrivez une note ..."
                    multiline
                    rows={4}
                    fullWidth
                    defaultValue=""
                    variant="filled"
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                id={"commentButtonSend"}
                primary={
                  <Button variant="outlined" onClick={addComment}>
                    Ajouter commentaire
                  </Button>
                }
              />
            </ListItem>
          </>
        ) : (
          <Typography fontWeight="900" maxWidth="400px">
            Commentaires indisponibles sur les prospects Pentair & SosBricolage
            & SIRETS incorrects.
            <br />
            Vérifiez le SIRET/SIREN (14 ou 9 chiffres sans espaces)
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default getComments;
